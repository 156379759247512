
<!-- no gutters is required for proper spacing -->

<div class="d-flex flex-column">
    <!-- title -->
    <div *ngIf="!!data.title" class="snackbar-title-container">
      <span class="snackbar-title">{{data.title}}</span>
    </div>
</div>

<div class="d-flex flex-column"> <!-- message container -->
  <div matSnackBarLabel class="d-flex align-items-center"> <!-- message content -->

    <!-- details -->
    <div class="snackbar-detail-container"  [ngStyle]="(!data.title && !data.moreDetailItems) ? {'height': '100%'} : ''">
      <div *ngIf="isDetailInfoArray else single">
        <div *ngIf="data.isListOrdered else unOrdered">
          <ol class="snackbar-list">
            <ng-container *ngTemplateOutlet="detailItems"></ng-container>
          </ol>
        </div>
        <ng-template #unOrdered>
          <ul class="snackbar-list">
            <ng-container *ngTemplateOutlet="detailItems"></ng-container>
          </ul>
        </ng-template>
        <ng-template #detailItems>
          <li *ngFor="let item of data.detailItems">
            {{ item }}
          </li>
        </ng-template>
      </div>

      <!-- details single item -->
      <ng-template #single>
        <p class="single-item-p">{{data.detailItems}}</p>
      </ng-template>
    </div>

    <!-- close icon -->
    <div matSnackBarActions class="action-container d-flex align-items-center justify-content-end">
      <div (click)="close()" class="mat-simple-snackbar-action d-flex justify-content-center" style="width: inherit;">
        <button matSnackBarAction mat-button class="snackbar-close-btn">
          X   
        </button>
      </div>
    </div>
  </div> <!-- end message content -->
</div> <!-- end message container -->


<div class="d-flex flex-wrap flex-column justify-content-center"> <!-- server details container -->
  <!-- more data icon -->
  <div (click)="displayServerDetails()" class="snackbar-more-icon" *ngIf="data.moreDetailItems">
    <span *ngIf="data.isMoreSymbolIcon else noIconMore">
      <mat-icon class="fa" [class]="showServerErrors ? data.closeExpandSymbol : data.expandSymbol"></mat-icon>
    </span>
    <ng-template #noIconMore>
      <span>{{showServerErrors ? data.closeExpandSymbol : data.expandSymbol}}</span>
    </ng-template>
  </div>

  <!-- more data details -->
  <div *ngIf="isMoreDetailInfoArray else singleServer">
    <div *ngIf="data.isListOrdered else unOrdered">
      <ol class="snackbar-list">
        <ng-container *ngTemplateOutlet="moreDetailItems"></ng-container>
      </ol>
    </div>
    <ng-template #unOrdered>
      <ul class="snackbar-list">
        <ng-container *ngTemplateOutlet="moreDetailItems"></ng-container>
      </ul>
    </ng-template>
    <ng-template #moreDetailItems>
      <li *ngFor="let item of data.moreDetailItems">
        {{ item }}
      </li>
    </ng-template>
  </div>

  <!-- more data details single item-->
  <ng-template #singleServer>
    <p class="single-server-item-p" *ngIf="showServerErrors">{{data.moreDetailItems}}</p>
  </ng-template>
</div> <!-- end server details container -->

 


import { AUSnackBarData } from '@app/shared/models/snackbars/au-snackbar-data.model';
import { AUSnackBarTypes } from '@app/shared/models/snackbars/au-snackbar-types.enum';
import { MatSnackBarConfig } from '@angular/material/snack-bar';

export class AUSnackBarConfig {
  public error: any;
  public type: AUSnackBarTypes;

  private _config: MatSnackBarConfig;
  get config(): MatSnackBarConfig {
    return this._config;
  }

  get data(): AUSnackBarData {
    return this._config.data as AUSnackBarData;
  }
  constructor(
    type: AUSnackBarTypes,
    title?: string,
    details?: string | string[],
    moreDetails?: string | string[]
  ) {
    this._config = new MatSnackBarConfig();
    this._config.data = new AUSnackBarData();
    this.type = type;

    if (type === AUSnackBarTypes.Error) {
      this._config.panelClass = 'snack-bar-error';
      this._config.duration = undefined;
    } else if (type === AUSnackBarTypes.Success) {
      this._config.panelClass = 'snack-bar-success';
      this._config.duration = 5000;
    } else if (type === AUSnackBarTypes.Info) {
      this._config.panelClass = 'snack-bar-info';
      this._config.duration = undefined;
    } else {
      throw Error('Invalid snackbar type given');
    }

    this.data.title = title;
    this.data.detailItems = details;
    this.data.moreDetailItems = moreDetails;

    if (Array.isArray(this.data.detailItems) && this.data.detailItems.length === 1) {
      this.data.detailItems = this.data.detailItems[0];
    }
  }

  setPanelClass(panelClass: string): AUSnackBarConfig {
    this._config.panelClass = panelClass;
    return this;
  }

  setDuration(duration: number): AUSnackBarConfig {
    this._config.duration = duration;
    return this;
  }

  setDetailData(details: string | string[]): AUSnackBarConfig {
    this.data.detailItems = details;
    return this;
  }

  setMoreDetailData(moreDetails: string | string[]): AUSnackBarConfig {
    this.data.moreDetailItems = moreDetails;
    return this;
  }

  setTitle(title: string): AUSnackBarConfig {
    this.data.title = title;
    return this;
  }
}

import { AfterContentInit, Directive, HostListener } from '@angular/core';
import { UntypedFormControl, NgControl } from '@angular/forms';
import { hasValue } from '@app/shared/utilities/comparison-helpers.utility';

/**
 * This should be used to control error behavior and styling for
 * `required` errors on form controls. If the form control has no
 * value after blur AND is required the directive should mark it as
 * untouched to prevent the default styling.
 *
 * Note 12/13/2021: This behavior is reflected in various forms and the logic
 * for it typically resides at the component level. This directive should be
 * used to replace that duplicated component logic when possible.
 */
@Directive({ selector: '[acsRequiredErrorClear]' })
export class RequiredErrorClearDirective implements AfterContentInit {
  private formControl: UntypedFormControl;

  constructor(private baseControl: NgControl) {}

  @HostListener('blur')
  onBlur(): void {
    if (
      !hasValue(this.formControl.value) ||
      (!Boolean(this.formControl.value) && this.formControl.hasError('required'))
    ) {
      this.formControl.markAsUntouched();
      setTimeout(() => {
        this.formControl.markAsUntouched();
      });
    }
  }

  ngAfterContentInit(): void {
    this.formControl = this.baseControl.control as UntypedFormControl;
  }
}

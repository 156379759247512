import {
  AfterContentInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { formatNumber } from '@app/shared/utilities/number.utility';
/**
 * Low-level directive for manipulating the number input to fit the view and model.
 * The directive receives a string and returns a parsed and rounded float.
 */

@Directive({ selector: '[acsNumberFormat]' })
export class NumberFormatDirective implements OnInit, AfterContentInit, OnDestroy {
  @Input() decimalPlaces = 2;
  @Input() includeCommas = false;
  @Input() returnEmptyString = true;
  @Input() formatControl = false;

  private el: HTMLInputElement;
  private valueChangesSub: Subscription;

  constructor(@Optional() private baseControl: NgControl, private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string): void {
    if (value && parseFloat(value)) {
      this.el.value = formatNumber(
        value,
        this.includeCommas,
        this.decimalPlaces,
        this.returnEmptyString
      );
    }
  }

  ngOnInit(): void {
    if (this.baseControl && this.formatControl) {
      this.baseControl.control.valueChanges.subscribe({
        next: (val) => {
          if (this.el !== document.activeElement && val) {
            const formattedValue = formatNumber(
              val,
              this.includeCommas,
              this.decimalPlaces,
              this.returnEmptyString
            );

            this.baseControl.control.patchValue(formattedValue, {
              emitEvent: false,
              emitViewToModelChange: false
            });
          }
        }
      });
    }
  }

  ngAfterContentInit(): void {
    if (this.el.value) {
      this.el.value = formatNumber(
        this.el.value,
        this.includeCommas,
        this.decimalPlaces,
        this.returnEmptyString
      );
    }
  }

  ngOnDestroy(): void {
    this.valueChangesSub?.unsubscribe();
  }
}

export class TicketProductDeleteResponse {
  object = 'ticketProductDeleteResponse';
  deliveredQuantity: number | null;
  ticketed: boolean;

  constructor() {
    this.ticketed = false;
    this.deliveredQuantity = null;
  }
}

export class TicketProductDeletedEvent {
  ticketIds: number[];
  productId: number;
  deliveredQuantity: number | null;
  ticketed: boolean;

  constructor(
    ticketIds: number[],
    productId: number,
    deliveredQuantity: number | null = 0,
    ticketed = false
  ) {
    this.ticketIds = ticketIds;
    this.productId = productId;
    this.deliveredQuantity = deliveredQuantity;
    this.ticketed = ticketed;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * A generic component for an autosize text area.
 *
 * Note(1): This component will not work properly when used within a mat tab
 * on its own. Due to the way the tab renders and autosizes for its children
 * it won't always be the correct height for the textarea. To fix:
 * - Lazy load the tabs contents using `<ng-template matTabContent>`
 * - Dynamically display the acs-note component using ngIf
 *   - can be done using a reference variable to the mat-tab and the isActive field
 * - Explicitly set the height of the mat-tab
 */
@Component({
  selector: 'acs-note',
  template: `
    <mat-form-field class="col">
      <mat-label>{{ title }}</mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="{{ startingRows }}"
        cdkAutosizeMaxRows="{{ maxTextRows || 10 }}"
        (blur)="noteChanged()"
        [maxlength]="maxLength"
        [(ngModel)]="text"
        (keyup)="keyPressed()"
        acsNoLeadingSpace
      ></textarea>
    </mat-form-field>
  `
})
export class NoteComponent {
  @Input() maxLength: number;
  @Input() startingRows: number;
  @Input() maxTextRows: number;
  @Input() text: string;
  @Input() title: string;
  @Output() textChange = new EventEmitter<any>();

  constructor() {}

  public noteChanged(): void {
    this.textChange.emit(this.text);
  }

  public keyPressed(): void {
    this.textChange.emit(this.text);
  }
}

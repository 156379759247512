/*
 * Allows for user entered, comma delimited, and dash separated ranges.
 *
 * ex for `7`:
 *   `1,2,6,7`    => true
 *   `3,4,6-8,10` => true
 *   `4,5,8`      => false
 */
export const numberIsInRange = (searchNum: number, val: string): boolean => {
  if (!val) {
    return false;
  }

  const plans = val.split(',');

  return plans.some((n: string) => {
    if (n.indexOf('-') > -1 && numberIsInStringRange(searchNum, n)) {
      return true;
    } else if (searchNum === parseInt(n, 10)) {
      return true;
    }
  });
};

/*
 * Use to see if number is in a dash delimited string between two numbers,
 * inclusively. Anything after an extra dash is ignored.
 *
 * ex for `7`:
 *   `4-9`   => true
 *   `3-7`   => true
 *   `2-6`   => false
 *   `3-5-7` => false
 */
export const numberIsInStringRange = (searchNum: number, searchRange: string): boolean => {
  const rangeValues = searchRange.split('-');
  return searchNum >= parseInt(rangeValues[0], 10) && searchNum <= parseInt(rangeValues[1], 10);
};

/*
 * Use to create a string based representation of a passed in value.
 * Can separate by commas and set a specific number of decimal places.
 */
export const formatNumber = (
  toConvert: any,
  formatWithCommas: boolean,
  decimalPlaces: number,
  returnEmptyString = true
): string => {
  const parsedvalue = parseFloat(toConvert);

  if (parsedvalue) {
    const roundedValue = parsedvalue.toFixed(decimalPlaces);

    if (!formatWithCommas) {
      return roundedValue;
    }

    const roundedValueParts = roundedValue.split('.');
    const commaFormattedSection = roundedValueParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${commaFormattedSection}.${roundedValueParts[1]}`;
  } else {
    if (returnEmptyString) {
      return '';
    }

    if (!decimalPlaces) {
      return '0';
    }

    let defaultBase = '0.';

    for (let i = 0; i < decimalPlaces; i++) {
      defaultBase += '0';
    }

    return defaultBase;
  }
};

/**
 * Use to truncate decimal places off of a number without
 * performing any rounding
 */
export const formatNumberNoRounding = (toConvert: number, decimalPlaces: number): number => {
  const numString = toConvert.toString();
  const decimalIndex = numString.indexOf('.');
  const subStrEndIndex = decimalIndex === -1 ? numString.length : decimalIndex + decimalPlaces + 1;
  const substr = numString.substring(0, subStrEndIndex);

  return Number.parseFloat(substr);
};

/*
 * Use to check if a string value has only numbers, commas, dashes, and spaces.
 * May refactor completely using regex in the future
 */
export const numbersOrRange = (valueToTest: string): boolean => {
  // Check if a string does not start or end with an invalid character
  if (/^[,\-\ ]*$/.test(valueToTest[0]) || /^[,\-\ ]*$/.test(valueToTest[valueToTest.length - 1])) {
    return false;
  }

  for (let i = 0; i < valueToTest.length; i++) {
    // Check if there is not a duplicate seperator character next to one another
    if (
      /^[,\-]*$/.test(valueToTest[i]) &&
      (valueToTest[i] === valueToTest[i - 1] || valueToTest[i] === valueToTest[i + 1])
    ) {
      return false;
    }

    // Checks if there is not a hanging dash with no number following the dash
    if (/^[\-]*$/.test(valueToTest[i]) && !/^[0-9]*$/.test(valueToTest[i + 1])) {
      return false;
    }

    // Checks if there is random spaces between numbers with no seperator
    if (
      /^[0-9]*$/.test(valueToTest[i]) &&
      /^[\ ]*$/.test(valueToTest[i + 1]) &&
      /^[0-9]*$/.test(valueToTest[i + 2])
    ) {
      return false;
    }
  }

  // Check if the string only includes numbers, dashes, commas and spaces
  return /^([0-9,\-\ ])*$/.test(valueToTest);
};

<div mat-dialog-title class="d-flex">
  <h2>{{data.title}}</h2>
  <button class="ml-auto" color="accent" mat-button (click)="onNoClick()" tabindex="-1">
    <mat-icon color="accent" fontIcon="fa-times"></mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div class="row justify-content-end" *ngIf="showNewBtn">
    <button class="m-1" mat-raised-button type="button" (click)="clickedNew()">New</button>
  </div>

  <mat-table
    #genericTable
    [dataSource]="dataSource"
    acsArrowKeyNavigator
    class="compact-data-table"
    (rowSelected)="lookupAndSend($event)"
    [ignoreFirstElement]="true"
    [(currentIndex)]="currentIndex"
    (contentChanged)="tableContentChanged()"
    matSort>
  <ng-container [matColumnDef]="column.displayValue" *ngFor="let column of columnData">
    <mat-header-cell *matHeaderCellDef mat-sort-header="{{column.displayValue}}"> {{column.displayValue}} </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{column.formatter ? column.formatter(element[column.objectValue]) : element[column.objectValue]}} </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="columnHeaders; sticky: true"></mat-header-row>
  <mat-row
    *matRowDef="let row; columns: columnHeaders;"
    (click)="sendItem(row)"
    [attr.elementId]="data.pkField ? row[data.pkField] : undefined"
    tabindex="-1">
  </mat-row>
</mat-table>

</div>

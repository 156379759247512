import {
  AUSnackBarComponent,
  AUSnackBarConfig,
  AUSnackBarData,
  AUSnackBarTypes
} from '@app/notifications/snackbars/au-snackbar.barrel';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { Observable, Subject, Subscription } from 'rxjs';
import { hasValue } from '@app/shared/utilities/comparison-helpers.utility';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AUSnackBarService {
  // private _cachedErrorData: ErrorCacheObject[] = [];
  private _currentSnackBarRef: MatSnackBarRef<AUSnackBarComponent>;
  private _updateSnackbarSubject = new Subject<AUSnackBarData>();
  private _sbDismissSubscription: Subscription;

  private set currentSnackBarRef(value: MatSnackBarRef<AUSnackBarComponent>) {
    if (this._currentSnackBarRef || this._sbDismissSubscription) {
      this._sbDismissSubscription.unsubscribe();
      this._sbDismissSubscription = undefined;
    }

    this._currentSnackBarRef = value;

    this._sbDismissSubscription = this._currentSnackBarRef
      .afterDismissed()
      .pipe(take(1))
      .subscribe(() => {
        this._currentSnackBarRef = undefined;
      });
  }

  get updateSnackbarObservable(): Observable<AUSnackBarData> {
    return this._updateSnackbarSubject.asObservable();
  }

  constructor(private snackBar: MatSnackBar) {}

  public displayReadOnlyMsg() {
    this._currentSnackBarRef?.dismiss();
    const message = 'Form is in read-only mode';
    const config = new AUSnackBarConfig(AUSnackBarTypes.Info, '', message);
    config.setDuration(4000);
    const snackBarRef = this.snackBar.openFromComponent(AUSnackBarComponent, config.config);
    this.currentSnackBarRef = snackBarRef;

    return snackBarRef;
  }

  public open(snackBarConfig: AUSnackBarConfig, overwrite = true): MatSnackBarRef<any> {
    if (!overwrite) {
      if (hasValue(this._currentSnackBarRef)) {
        return;
      }
    }

    const snackBarRef = this.snackBar.openFromComponent(AUSnackBarComponent, snackBarConfig.config);

    this.currentSnackBarRef = snackBarRef;

    return snackBarRef;
  }

  public openSimple(typeBool: boolean, message: string, timeout = 0, servDesc?: string) {
    this._currentSnackBarRef?.dismiss();

    const sbType = typeBool ? AUSnackBarTypes.Success : AUSnackBarTypes.Error;
    const config = new AUSnackBarConfig(sbType, '', message, servDesc);

    if (timeout !== 0) {
      config.setDuration(timeout);
    }

    // setting to 0 for typeBool: true will prevent auto-closing after 5 seconds.
    // used for snackbar reference closing
    if (timeout === 0) {
      config.setDuration(undefined);
    }

    const snackBarRef = this.snackBar.openFromComponent(AUSnackBarComponent, config.config);

    this.currentSnackBarRef = snackBarRef;

    return snackBarRef;
  }

  public openSimpleByType(
    type: AUSnackBarTypes,
    message: string,
    timeout?: number,
    servDesc?: string
  ) {
    this._currentSnackBarRef?.dismiss();

    const config = new AUSnackBarConfig(type, '', message, servDesc);

    if (timeout) {
      config.setDuration(timeout);
    }

    const snackBarRef = this.snackBar.openFromComponent(AUSnackBarComponent, config.config);

    this.currentSnackBarRef = snackBarRef;

    return snackBarRef;
  }

  public update(snackBarConfig: AUSnackBarConfig): void {
    if (hasValue(this._currentSnackBarRef)) {
      this._updateSnackbarSubject.next(snackBarConfig.data);
    } else {
      this.open(snackBarConfig);
    }
  }

  public openDefault(message: string, action: string, panelClass: string) {
    const config = new MatSnackBarConfig();
    config.panelClass = panelClass;
    this.snackBar.open(message, action, config);
  }

  public close(): void {
    this._currentSnackBarRef?.dismiss();
  }

  // private cacheInfo(config: AUSnackBarConfig): void {
  //   if (config.type === AUSnackBarTypes.Error) {
  //     this._cachedErrorData.push(
  //       new ErrorCacheObject(config.data.title, config.data.detailItems, config.error)
  //     );
  //   }
  // }
}

// class ErrorCacheObject {
//   title: string;
//   details: string | string[];
//   additionalData: any;

//   constructor(title: string, details: string | string[], additionalData: any) {
//     this.title = title;
//     this.details = details;
//     this.additionalData = additionalData;
//   }
// }

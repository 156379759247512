import { Directive, HostListener, Input, Optional } from '@angular/core';
import { Logger } from '@app/logger/logger';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { NgControl } from '@angular/forms';

/**
 * This directive should only be used in tandem with mat-autcomplete
 * components and form controls. It will allow the values of the
 * mat-autocomplete and the form control to be set to the currently
 * active option by pressing tab.
 *
 * The directive should be placed on the associated <input/> element and not
 * the <mat-autocomplete> element.
 *
 * Note 2023-10-17: Not necessary for selecting a value with usage of autoSelectActiveOption
 * on the mat-autocomplete instance in the template.
 *
 * Note 2023-10-20: autoSelectActiveOption doesn't seem to be working correctly. There must be
 * some issue with its implementation. For now this directive works as a good replacement.
 */
@Directive({
  selector: '[acsAutoCompleteTab]'
})
export class AutocompleteTabDirective {
  @Input() emitChange = true;

  constructor(
    @Optional() private autoTrigger: MatAutocompleteTrigger,
    @Optional() private ngControl: NgControl,
    private logger: Logger
  ) {
    if (!this.autoTrigger) {
      this.logger.warn('Directive could not find MatAutocompleteTrigger');
    }
  }

  @HostListener('keydown.tab', ['$event.target']) onBlur() {
    if (this.autoTrigger?.activeOption && this.autoTrigger.autocomplete.isOpen) {
      const activeValue = this.autoTrigger.activeOption.value;
      if (this.ngControl) {
        this.ngControl.control.setValue(activeValue, { emitEvent: this.emitChange });
        this.autoTrigger.writeValue(activeValue);
      }
    }
  }
}

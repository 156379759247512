import { Injectable } from '@angular/core';
import { SystemParameterService } from '@app/system-parameters/shared/system-parameters.service';
import { hasValue } from '@app/shared/utilities/comparison-helpers.utility';

@Injectable({ providedIn: 'root' })
export class PhoneNumberMaskValidatorService {
  public phoneNumberMask: string;
  public rawMaskLength: number;

  get isMaskAvailable(): boolean {
    return hasValue(this.phoneNumberMask) && this.phoneNumberMask !== '' && this.rawMaskLength > 0;
  }

  constructor(private systemParameterService: SystemParameterService) {
    this.systemParameterService.getParameters().subscribe((systemParameters) => {
      this.phoneNumberMask = systemParameters.phoneNumberInputMask;
      this.rawMaskLength = this.phoneNumberMask?.split('#').length - 1 || 0;
    });
  }

  public isPhoneNumberValid(phoneNumber: string, isMasked?: boolean): boolean {
    return this.addMaskWithPlaceHolders(phoneNumber, isMasked).indexOf('#') === -1;
  }

  public stripMask(phoneNumber: string): string {
    if (phoneNumber) {
      return phoneNumber.replace(/[^0-9]/g, '');
    }

    return '';
  }

  public addMask(originalValue: string, deleting?: boolean): string {
    if (originalValue && this.phoneNumberMask?.length) {
      let newNumber = originalValue.replace(/[^0-9]/g, '');
      if (newNumber) {
        let oCount;
        for (oCount = 0; oCount <= originalValue.length - 1; oCount++) {
          const item = this.phoneNumberMask[oCount];
          if (item !== '#') {
            newNumber = [newNumber.slice(0, oCount), item, newNumber.slice(oCount)].join('');
          }
        }

        if (!deleting) {
          const next = this.phoneNumberMask[oCount];
          if (next !== '#') {
            newNumber = [newNumber.slice(0, oCount), next, newNumber.slice(oCount)].join('');
          }
        }

        if (newNumber.length > this.phoneNumberMask.length) {
          newNumber = newNumber.substring(0, this.phoneNumberMask.length);
        }

        return newNumber.trim();
      }
    }

    return '';
  }

  public addMaskWithPlaceHolders(originalValue: string, isMasked?: boolean): string {
    let maskedNumber = isMasked ? originalValue : this.addMask(originalValue);

    if (maskedNumber) {
      if (maskedNumber.length <= this.phoneNumberMask.length) {
        maskedNumber += this.phoneNumberMask.slice(maskedNumber.length);
      }
    }

    return maskedNumber;
  }
}

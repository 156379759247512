export class BatchWeight {
  ticketId: number;
  productId: number;
  productNumber: string;
  description: string;
  imperialDescription: string;
  targetQuantity: number;
  actualQuantity: number;
  object: string;

  constructor() {}
}

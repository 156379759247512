import { Directive, HostListener } from '@angular/core';
import { KEY_CODE } from '@app/shared/constants/key-code.const';
import { LoginStatusService } from '@app/security/shared/login-status.service';
import { Router } from '@angular/router';

@Directive({
  selector: '[acsShortcutListener]'
})
export class ShortcutListenerDirective {
  constructor(private loginService: LoginStatusService, private router: Router) {}

  @HostListener('window:keyup', ['$event'])
  KeyEvent(event: KeyboardEvent): void {
    if (this.loginService.canActivate()) {
      if (event.ctrlKey && event.shiftKey && event.altKey) {
        switch (event.key) {
          case KEY_CODE.F12:
            this.router.navigate(['/debug']);
            break;
          default:
            break;
        }
      }
    }
  }
}

export const formatInputForDollarOrPercent = (
  inputtedValue,
  percentageFlag: boolean,
  useCommas = true,
  percentFractionalDigits?: number
): string => {
  if (
    typeof inputtedValue === 'string' &&
    (inputtedValue.includes('%') || inputtedValue.includes('$'))
  ) {
    inputtedValue = formatDollarOrPercentValueForModel(inputtedValue);
  }

  const parsedValue: number =
    typeof inputtedValue === 'string' ? parseFloat(inputtedValue.trim()) : inputtedValue;

  let formattedNumber;
  if (parsedValue) {
    if (percentageFlag) {
      const percentage = (parsedValue / 100) * 10000;
      formattedNumber = percentage.toFixed(percentFractionalDigits || 2) + '%';
    } else {
      formattedNumber = '$' + parsedValue.toFixed(2);

      if (useCommas) {
        formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    }

    return formattedNumber;
  } else if (parsedValue === 0) {
    if (!percentageFlag) {
      return '$0.00';
    } else {
      return '0.00';
    }
  }

  return '';
};

export const formatDollarOrPercentValueForModel = (inputValue: string): number => {
  if (inputValue && inputValue.trim()) {
    const trimmedValue = inputValue.replace(',', '');
    if (trimmedValue.includes('$')) {
      const splitNumArr = trimmedValue.split('$');
      return parseFloat(splitNumArr[1]);
    } else if (trimmedValue.includes('%')) {
      const splitNumArr = trimmedValue.split('%');
      const formattedNumber = parseFloat(splitNumArr[0]) / 100;
      return parseFloat(formattedNumber.toFixed(6));
    } else {
      return parseFloat(trimmedValue);
    }
  }

  return undefined;
};

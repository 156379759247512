import moment from 'moment';
import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { DocumentRefService } from '@app/shared/services/browser/document-ref.service';
import { KEY_CODE } from '@app/shared/constants/key-code.const';
import { MatCalendar, MatDatepicker } from '@angular/material/datepicker';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { auditTime } from 'rxjs/operators';
import { hasValue } from '@app/shared/utilities/comparison-helpers.utility';

const rightArrowEvent = new KeyboardEvent('keydown', {
  keyCode: 39,
  key: 'ArrowRight'
} as KeyboardEventInit);

const leftArrowEvent = new KeyboardEvent('keydown', {
  keyCode: 37,
  key: 'ArrowLeft'
} as KeyboardEventInit);

const generalDateFieldKeys = [
  'Backspace', // BACKSPACE
  'Tab', // TAB
  'Enter', // ENTER
  'Escape', // ESCAPE
  'Delete', // DELETE
  '/' // FORWARD SLASH
];

@Directive({
  selector: '[acsDatePickerEvtHandler]'
})
export class DatepickerHelperDirective implements OnInit {
  @Input() pickerEvtDebounceTime: number;
  @Input() closeDatePickerAfterReset = true;
  @Output() keypressDateChange: EventEmitter<moment.Moment> = new EventEmitter();

  private emitSubject = new Subject<moment.Moment>();

  /**
   * Get a reference to the MatCalendar component associated to this
   * datepicker. We may need to update this for future versions of material.
   *
   * We have to access it indirectly through private fields because the
   * MatDatePicker doesn't offer direct access. Based on github issues this
   * doesn't seem like something Material will offer anytime soon.
   */
  private get matCalendar(): MatCalendar<moment.Moment> | undefined {
    if (!this.datePicker) {
      return undefined;
    }

    return this.datePicker['_componentRef']['instance']['_calendar'];
  }

  constructor(
    @Optional() private baseControl: NgControl,
    @Optional() private datePicker: MatDatepicker<moment.Moment>,
    private documentService: DocumentRefService
  ) {}

  ngOnInit(): void {
    if (hasValue(this.baseControl)) {
      this.emitSubject.pipe(auditTime(this.pickerEvtDebounceTime)).subscribe((data) => {
        this.keypressDateChange.emit(data);
      });
    }

    if (this.datePicker) {
      this.datePicker.openedStream.subscribe(() => {
        this.createAndAppendButtonElement();
        this.pickerOpened();
      });
    }
  }

  public pickerOpened(): void {
    const calendarContent = document.getElementsByClassName('mat-calendar-content')[0];
    if (hasValue(calendarContent)) {
      calendarContent.addEventListener('keydown', (keydown: KeyboardEvent) => {
        const calendarBody = document.getElementsByClassName('mat-calendar-body')[0];
        if (hasValue(calendarBody)) {
          if (keydown.key === 'Shift') {
            this.handleShiftKeyPressed();
          }
          if (keydown.code === 'NumpadAdd') {
            calendarBody.dispatchEvent(rightArrowEvent);
          } else if (keydown.code === 'NumpadSubtract') {
            calendarBody.dispatchEvent(leftArrowEvent);
          }
        }
      });
    }
  }

  @HostListener('keydown', ['$event', '$event.target.value'])
  inputKeyPressed(event: KeyboardEvent, currentInputValue: any): void {
    // allow predefined keys
    if (
      generalDateFieldKeys.indexOf(event.key) !== -1 ||
      ((event.ctrlKey || event.metaKey) &&
        (event.key === KEY_CODE.A ||
          event.key === KEY_CODE.C ||
          event.key === KEY_CODE.V ||
          event.key === KEY_CODE.X ||
          event.key === KEY_CODE.Z)) ||
      event.key === KEY_CODE.RIGHT_ARROW ||
      event.key === KEY_CODE.LEFT_ARROW ||
      event.key === KEY_CODE.HOME ||
      event.key === KEY_CODE.END ||
      parseInt(event.key, 10) ||
      parseInt(event.key, 10) === 0
    ) {
      return;
    }

    const isSpecialEventKey =
      event.code === 'NumpadAdd' ||
      event.code === 'NumpadSubtract' ||
      event.code === 'ShiftLeft' ||
      event.code === 'ShiftRight' ||
      event.code === 'Shift';

    if (hasValue(this.baseControl) && isSpecialEventKey) {
      event.preventDefault();

      if (!hasValue(currentInputValue) || currentInputValue === '') {
        this.baseControl.control.setValue(moment());
      } else {
        const valueAsMoment = moment(currentInputValue, 'L');
        if (event.code === 'ShiftLeft' || event.code === 'ShiftRight' || event.code === 'Shift') {
          const currentDate = moment();
          this.baseControl.control.setValue(currentDate);
          this.emitSubject.next(currentDate);
        } else if (event.code === 'NumpadAdd') {
          const nextDate = valueAsMoment.add(1, 'day');
          this.baseControl.control.setValue(nextDate);
          this.emitSubject.next(nextDate);
        } else if (event.code === 'NumpadSubtract') {
          const previousDate = valueAsMoment.subtract(1, 'day');
          this.baseControl.control.setValue(previousDate);
          this.emitSubject.next(previousDate);
        }
      }

      this.baseControl.control.markAsDirty();
    } else {
      event.preventDefault();
    }
  }

  private handleShiftKeyPressed(): void {
    const currentDate = moment();
    this.datePicker.select(currentDate);

    if (this.matCalendar) {
      this.matCalendar.activeDate = currentDate;
    }

    if (this.closeDatePickerAfterReset) {
      this.datePicker.close();
    }
  }

  private createAndAppendButtonElement(): void {
    const calendar = document.getElementsByTagName('mat-calendar')[0] as HTMLElement;
    const button = this.documentService.createElement('button');
    const buttonText = this.documentService.createTextNode('Today');
    this.documentService.applyStyleToElement(button, 'marginTop', '5px');
    this.documentService.applyStyleToElement(button, 'marginRight', 'auto');
    this.documentService.applyStyleToElement(button, 'marginLeft', 'auto');
    this.documentService.applyStyleToElement(button, 'display', 'flex');
    this.documentService.applyStyleToElement(button, 'border', 'none');
    this.documentService.applyStyleToElement(button, 'backgroundColor', 'transparent');
    this.documentService.applyStyleToElement(button, 'color', '#23b7e5');

    this.documentService.addEventListener(button, 'click', this.handleShiftKeyPressed.bind(this));

    // add the text node to the button node
    button.appendChild(buttonText);

    //calendarContent.appendChild(button);
    calendar.appendChild(button);

    // set new height of calendar to ensure it will contain button
    calendar.style.height = calendar.offsetHeight + button.offsetHeight + 15 + 'px';
  }
}

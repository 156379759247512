<div mat-dialog-title class="row d-flex">
  <h2>{{ data.title }}</h2>
  <button mat-button class="ml-auto" (click)="onNoClick()" tabindex="-1">
    <mat-icon color="accent" fontIcon="fa-times"></mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <mat-form-field class="col justify-content-center pt-4">
    <mat-label *ngIf="data.inputLabel">{{data.inputLabel}}</mat-label>
    <input [maxlength]="data.maxLength" matInput autocomplete="off" [(ngModel)]="data.value">
    <button *ngIf="data.value" color="warn" matIconSuffix mat-icon-button aria-label="Clear" (click)="data.value=''">
      <mat-icon class="fa fa-close"></mat-icon>
    </button>
  </mat-form-field>
</div>

<div mat-dialog-actions class="pr-2">
  <button mat-raised-button color="primary" class="ml-auto" [mat-dialog-close]="data.value">
    {{ data.confirmText ? data.confirmText : 'Save' }}
  </button>
  <button class="mr-1" mat-raised-button [mat-dialog-close]="false">
    {{ data.confirmText ? data.denyText : 'Cancel' }}
  </button>
</div>

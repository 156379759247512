export class PrintOptions {
  printType: PrintType;
  requiresOrientation: boolean;

  constructor(printType: PrintType, requiresOrientation: boolean) {
    this.requiresOrientation = requiresOrientation;
    this.printType = printType;
  }
}

export class TicketPrintOptions extends PrintOptions {
  reprint: boolean;
  ticketId: number;

  constructor(
    reprint: boolean,
    ticketId: number,
    printType: PrintType,
    requiresOrientation: boolean
  ) {
    super(printType, requiresOrientation);
    this.reprint = reprint;
    this.ticketId = ticketId;
  }
}

export class TicketPrintGroupOptions extends PrintOptions {
  ticketPrintGroupDetails: Array<TicketPrintGroupDetail>;
  requestId: string;
  ticketPrintQueue: string;

  constructor(
    printDetails: Array<TicketPrintGroupDetail>,
    printType: PrintType,
    requiresOrientation: boolean
  ) {
    super(printType, requiresOrientation);
    this.ticketPrintGroupDetails = printDetails;
  }
}

export class TicketPrintGroupDetail {
  reprint: boolean;
  ticketId: number;

  constructor(reprint?: boolean, ticketId?: number) {
    if (reprint) {
      this.reprint = reprint;
    }

    if (ticketId) {
      this.ticketId = ticketId;
    }
  }
}

export enum PrintType {
  Ticket
}

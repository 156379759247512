import {
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import { ElementRef } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: 'button[acsBusySpinner]'
})
export class ProgressSpinnerDirective implements OnInit, OnChanges, OnDestroy {
  @Input() acsBusySpinner: boolean;
  @Input() altColor: boolean;

  public spinner: MatProgressSpinner;

  private elementChildren: HTMLCollection;
  private eventSubscription: Subscription;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.createSpinnerElement();

    this.elementChildren = (this.el.nativeElement as HTMLElement).children;

    this.eventSubscription = fromEvent(this.el.nativeElement.parentNode, 'click', {
      capture: true
    }).subscribe((e: any) => {
      if (this.acsBusySpinner) {
        e.stopPropagation();
      }
    });
  }

  ngOnDestroy(): void {
    this.eventSubscription?.unsubscribe();
  }

  ngOnChanges(onChangesObj: SimpleChanges): void {
    if (
      typeof onChangesObj.acsBusySpinner === 'object' &&
      !onChangesObj.acsBusySpinner.isFirstChange()
    ) {
      if (onChangesObj.acsBusySpinner.currentValue === true) {
        this.updateElementChildrenDisplay('none');
        this.renderer.setStyle(this.spinner._elementRef.nativeElement, 'display', 'inherit');
      } else {
        this.updateElementChildrenDisplay('inherit');
        this.renderer.setStyle(this.spinner._elementRef.nativeElement, 'display', 'none');
      }
    }
  }

  private updateElementChildrenDisplay(displayValue: string): void {
    for (let i = 0; i < this.elementChildren.length; i++) {
      const el = this.elementChildren.item(i) as HTMLElement;
      this.renderer.setStyle(el, 'display', displayValue);
    }
  }

  private createSpinnerElement(): void {
    const componentRef = this.viewContainerRef.createComponent(MatProgressSpinner);
    this.spinner = componentRef.instance;

    // Configure the spinner
    this.spinner.strokeWidth = 3;
    this.spinner.diameter = 24;
    this.spinner.mode = 'indeterminate';
    if (this.altColor) {
      this.spinner.color = 'accent';
    }

    this.renderer.setStyle(this.spinner._elementRef.nativeElement, 'display', 'none');

    this.renderer.appendChild(this.el.nativeElement, this.spinner._elementRef.nativeElement);
  }
}

import { AppConfig } from '@app/app.config';
import { HttpService } from '@app/security/shared/http.service';
import { Injectable } from '@angular/core';
import { Logger } from '@app/logger/logger';
import { Observable, throwError } from 'rxjs';
import { PrintDestination } from '@app/shared/models/print-destination.model';
import { catchError } from 'rxjs/operators';

const lgSfx = '\t(db.service)';

@Injectable({ providedIn: 'root' })
export class PrinterService {
  constructor(private config: AppConfig, private http: HttpService, private log: Logger) {}

  public getPrinters(): Observable<Array<PrintDestination>> {
    const API_ENDPOINT = '/api/v1/print-destinations';
    const endpoint = this.config.getServerUri() + API_ENDPOINT;

    return this.http.getList<PrintDestination>(endpoint, PrintDestination, undefined, true).pipe(
      catchError((err) => {
        this.log.error(`Error retrieving printer list. Status: ${err.status} ${lgSfx}`, err);
        return throwError('Error retrieving printer list.');
      })
    );
  }

  public openPrintWindow(url: string, id: string): void {
    this.log.log('Creating Print Window', url, id);

    if (url.includes('.pdf')) {
      const printWindow = window.open(
        url,
        `PRINT_WINDOW_${id}`,
        `height=${window.innerHeight * 0.9},width=${window.innerWidth * 1}`
      );

      this.log.log('Opening Print Window', printWindow);
    } else {
      throwError('Non .pdf files not currently supported in print window');
    }
  }
}

import moment from 'moment';
import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

// /**
//  * Low-level directive for manipulating the time to fit the view and model.
//  *
//  * doubleDigitsToHours: a directive flag used to check if the time should
//  * be converted to hours or minutes.
//  */

@Directive({
  selector: '[acsTimeFormat]'
})
export class TimeFormatDirective {
  @Input() doubleDigitsToHours: boolean;
  @Output() inputUpdated = new EventEmitter<any>();

  constructor(private el: ElementRef) {}

  @HostListener('blur', ['$event'])
  blurEvent(): void {
    let userInput = this.el.nativeElement.value;
    if (userInput) {
      if (userInput.includes(':')) {
        const splitInput = userInput.split(':');
        const hours = parseInt(splitInput[0], 10);
        const minutes = parseInt(splitInput[1], 10);
        userInput = hours * 60 + minutes;
      }
      if (this.doubleDigitsToHours) {
        if (userInput.length >= 4) {
          const input = userInput.substring(0, 4);
          const hours = parseInt(input.substring(0, 2), 10);
          const minutes = parseInt(input.substring(2, 4), 10);

          this.el.nativeElement.value = moment().hours(hours).minutes(minutes).format('HH:mm');
        } else if (userInput.length === 3) {
          const hours = parseInt(userInput.substring(0, 1), 10);
          const minutes = parseInt(userInput.substring(1, 3), 10);

          this.el.nativeElement.value = moment().hours(hours).minutes(minutes).format('HH:mm');
        } else if (userInput.length <= 2) {
          const hours = parseInt(userInput.substring(0, userInput.length), 10);

          this.el.nativeElement.value = moment().hours(hours).minutes(0).format('HH:mm');
        }
        this.inputUpdated.emit(this.el.nativeElement.value);
      } else {
        this.el.nativeElement.value = moment()
          .hours(0)
          .minutes(parseInt(userInput, 10))
          .format('HH:mm');
      }
    }
  }
}

import { Directive, ElementRef, OnInit } from '@angular/core';
import { WindowService } from '@app/shared/services/browser/window.service';

@Directive({ selector: '[acsMobileVisibility]' })
export class MobileVisibilityDirective implements OnInit {
  constructor(private el: ElementRef, private windowService: WindowService) {}

  ngOnInit(): void {
    if (this.windowService.isSmallDevice) {
      this.el.nativeElement.style.display = 'none';
    }
  }
}

import { Component, ElementRef } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'acs-clickable-grid-header',
  styles: ['.grid-header-padding { padding-left: 12px; padding-right: 12px; }'],
  templateUrl: './clickable-grid-header.component.html'
})
export class ClickableGridHeaderComponent implements AgRendererComponent {
  params: any;

  constructor(private elRef: ElementRef) {}

  public agInit(params: any): void {
    this.params = params;
    this.elRef.nativeElement.addEventListener('click', () => {
      params.context.componentParent.agGridHeaderClicked(params.column.colId);
    });
  }

  public refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}

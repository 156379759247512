import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'acs-progress-ring',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <svg [attr.height]="height" [attr.width]="width">
      <circle
        #circle
        [attr.stroke]="color"
        [attr.stroke-dasharray]="_circumference"
        [ngStyle]="{ 'stroke-dashoffset': _circumference }"
        [attr.stroke-width]="4"
        fill="transparent"
        [attr.r]="normalizedRadius"
        [attr.cx]="7"
        [attr.cy]="11"
      />
    </svg>

    <style>
      circle {
        transform: rotate(-90deg);
        transform-origin: 40% 50%;
      }
    </style>
  `
})
export class ProgressRingComponent implements AfterViewInit, OnChanges {
  @ViewChild('circle', { static: false })
  circle;
  @Input() value: number;
  @Input() color: string;
  @Input() height: string;
  @Input() width: string;

  public radius = 14;
  public normalizedRadius = this.radius - 4 * 2;
  public _circumference = this.normalizedRadius * 2 * Math.PI;

  constructor() {}

  ngAfterViewInit(): void {
    this.setProgress(this.value);
  }

  ngOnChanges(onChanges: SimpleChanges): void {
    if (onChanges.value && onChanges.value.previousValue !== onChanges.value.currentValue) {
      if (this.circle) {
        this.setProgress(this.value);
      }
    }
  }

  public setProgress(percent): void {
    this.circle.nativeElement.style.strokeDashoffset =
      this._circumference - (percent / 100) * this._circumference;
  }
}

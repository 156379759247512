import { AppConfig } from '@app/app.config';
import { BatchEditTicket } from '@app/ticketing/models/batch-edit-ticket.model';
import { BooleanResponse } from '@app/shared/models/boolean-response.model';
import { BatchWeight } from '@app/ticketing/ticket-entry/weight-table/batch-weight.model';
import { CalculateScheduleLoadChargesRequest } from '@app/ticketing/models/calculate-schedule-load-charges-request.model';
import { FORM_ID_HEADER } from '@app/shared/constants/constants';
import { HttpOptionsService, HttpService } from '@app/security/shared/http.service';
import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { Observable, of, Subject } from 'rxjs';
import { OrderCalculatedPrices } from '@app/orders/cod-total/order-calculated-prices.model';
import { RequestValid } from '@app/shared/models/request-valid.model';
import { SalesTax } from '@app/ticketing/models/sales-tax.model';
import { SearchResult } from '@app/shared/models/search-result.model';
import { SimpleTicket } from '@app/ticketing/models/simple-ticket.model';
import { Ticket } from '@app/ticketing/models/ticket.model';
import { TicketOrderId } from '@app/ticketing/models/ticket-order-id.model';
import { TicketPatch } from '@app/ticketing/models/ticket-patch.model';
import { TicketPrintGroupOptions } from '@app/shared/dialogs/print-dialog/models/print-options.model';
import { TicketPrintGroupResponse } from '@app/ticketing/models/printing/ticket-print-group-response.model';
import { TicketPrintReply } from '@app/ticketing/models/printing/ticket-print-reply.model';
import { TicketPrintRequest } from '@app/ticketing/models/printing/ticket-print-request.model';
import { TicketProductDeleteResponse } from '@app/ticketing/models/ticket-product-delete-response.model';
import { TicketStatus } from '@app/ticketing/models/ticket-status.model';
import { TicketStatusUpdate } from '@app/ticketing/models/ticket-status-update.model';
import { TicketTruckDetails } from '@app/ticketing/models/ticket-truck-details.model';
import { TicketUpdate } from '@app/ticketing/models/ticket-update.model';
import { TicketUpdateData } from '@app/ticketing/models/ticket-update-data.model';
import { VoidStatus } from '@app/ticketing/models/void-status.model';
import { map, mergeMap, publishReplay, refCount } from 'rxjs/operators';

const API_ENDPOINT = '/api/v1/tickets';
const API_ENDPOINT_V2 = '/api/v2/tickets';

export class TicketVoidEvent {
  ticketId: number;
  orderId: number;
  scheduleId: number;
}
@Injectable({ providedIn: 'root' })
export class TicketingService {
  private _editTicketFormClearEvent: Subject<void> = new Subject();
  private _ticketModifiedSubject: Subject<TicketUpdateData> = new Subject();
  private _ticketSavedEventSubject: Subject<TicketUpdate> = new Subject();
  private _ticketVoidedEventSubject: Subject<TicketVoidEvent> = new Subject();
  private voidStatuses: VoidStatus[];
  private loadVoidStatusesObserver: Observable<Array<VoidStatus>>;

  public get editTicketFormClearEvent$(): Observable<void> {
    return this._editTicketFormClearEvent;
  }

  public get globalTicketModified$(): Observable<TicketUpdateData> {
    return this._ticketModifiedSubject.asObservable();
  }

  public get ticketSavedEvent$(): Observable<TicketUpdate> {
    return this._ticketSavedEventSubject.asObservable();
  }

  public get ticketVoidedEvent$(): Observable<TicketVoidEvent> {
    return this._ticketVoidedEventSubject.asObservable();
  }

  constructor(
    private config: AppConfig,
    private http: HttpService,
    private optionsService: HttpOptionsService
  ) {}

  public updateTicketStatus(ticketStatusUpdate: TicketStatusUpdate): Observable<Ticket> {
    const endpoint =
      this.config.getServerUri() + API_ENDPOINT + `/${ticketStatusUpdate.ticketId}/status`;

    return this.http.post<Ticket>(endpoint, ticketStatusUpdate, Ticket, undefined, true);
  }

  public saveTicket(data: TicketUpdate): Observable<TicketUpdate> {
    const endpoint = this.config.getServerUri() + API_ENDPOINT;

    return this.http.post<TicketUpdate>(endpoint, data, TicketUpdate, undefined, true);
  }

  public getTickets(orderId: number, productId?: number): Observable<Array<SimpleTicket>> {
    const params = new URLSearchParams();
    if (orderId) {
      params.set('orderId', orderId.toString(10));
    }
    if (productId) {
      params.set('productId', productId.toString(10));
    }

    const endpoint = this.config.getServerUri() + API_ENDPOINT + `/?` + params.toString();

    return this.http.getList<SimpleTicket>(endpoint, SimpleTicket);
  }

  public getTicket(id: number): Observable<Ticket> {
    const endpoint = this.config.getServerUri() + API_ENDPOINT + `/${id}`;

    return this.http.get<Ticket>(endpoint, Ticket);
  }

  public getTicketStatuses(): Observable<Array<TicketStatus>> {
    const endpoint = this.config.getServerUri() + API_ENDPOINT + '/statuses';

    return this.http.getList<TicketStatus>(endpoint, TicketStatus);
  }

  public getVoidStatuses(): Observable<Array<VoidStatus>> {
    if (
      typeof this.loadVoidStatusesObserver === 'undefined' ||
      this.loadVoidStatusesObserver === null
    ) {
      this.loadVoidStatuses();
    }

    return this.loadVoidStatusesObserver.pipe(map(() => this.voidStatuses));
  }

  public loadVoidStatuses(): Observable<Array<VoidStatus>> {
    const endpoint = this.config.getServerUri() + '/api/v1/void-statuses';

    if (this.loadVoidStatusesObserver) {
      return this.loadVoidStatusesObserver;
    }

    this.loadVoidStatusesObserver = this.http.getList<VoidStatus>(endpoint, VoidStatus).pipe(
      map((voidStatuses: VoidStatus[]) => {
        this.voidStatuses = voidStatuses
          .filter((vs) => vs.id !== 0)
          .sort((a, b) =>
            a.description.localeCompare(b.description, undefined, { sensitivity: 'base' })
          );
        return this.voidStatuses;
      }),
      publishReplay(1),
      refCount()
    );
    return this.loadVoidStatusesObserver;
  }

  public voidTicket(ticket: Ticket, editTicket?: boolean, formId?: number): Observable<Ticket> {
    const params = new URLSearchParams();
    if (editTicket) {
      params.set('editTicket', editTicket.toString());
    }

    const endpoint =
      this.config.getServerUri() + API_ENDPOINT + `/${ticket.id}/void?` + params.toString();

    let options;

    if (formId) {
      options = this.optionsService.get();
      options.headers = options.headers.set(FORM_ID_HEADER, formId.toString());
    }

    return this.http.post<Ticket>(endpoint, ticket, Ticket, options, true);
  }

  public unvoidTicket(ticket: Ticket, editTicket?: boolean): Observable<Ticket> {
    let params = '';

    if (editTicket) {
      const editTicketParam = new URLSearchParams();
      editTicketParam.set('editTicket', editTicket.toString());
      params = `/?` + editTicketParam.toString();
    }

    const endpoint = this.config.getServerUri() + API_ENDPOINT + `/${ticket.id}/unvoid` + params;

    return this.http.post<Ticket>(endpoint, undefined, Ticket, undefined, true);
  }

  public checkTicketTruckDetails(
    truckId: number,
    driverId: number,
    date: Moment
  ): Observable<boolean> {
    const endpoint = this.config.getServerUri() + API_ENDPOINT + '/checks/truck-details';

    const data = {
      truckId,
      driverId,
      ticketDate: date.format('YYYY-MM-DD HH:mm:ss')
    };

    return this.http.post<TicketTruckDetails>(endpoint, data, TicketTruckDetails).pipe(
      mergeMap((ticketTruckDetails: TicketTruckDetails) => {
        const truckIsOut = ticketTruckDetails.truckId === truckId;
        const driverIsOut = ticketTruckDetails.specifiedDriverId === driverId;

        if (truckIsOut && driverIsOut) {
          throw new Error('The selected truck and driver are out. Do you want to continue?');
        } else if (truckIsOut) {
          throw new Error('The selected truck is out. Do you want to continue?');
        } else if (driverIsOut && driverId !== 0) {
          throw new Error('The specified driver is out. Do you want to continue?');
        } else {
          return of(true);
        }
      })
    );
  }

  public checkTicketNumberAvailable(
    ticketNumber: number,
    systemTypeId: number,
    plantId: number,
    scaleId?: number
  ): Observable<RequestValid> {
    const endpoint = this.config.getServerUri() + `/api/v1/tickets/checks/ticket-number-available`;

    const data = {
      systemTypeId,
      plantId,
      ticketNumber,
      scaleId
    };

    return this.http.post<RequestValid>(endpoint, data, RequestValid, undefined, true);
  }

  public getTicketUpdateForm(ticketId: number, formId?: number): Observable<TicketUpdate> {
    const endpoint = this.config.getServerUri() + API_ENDPOINT + `/${ticketId}/form`;

    let options;

    if (formId) {
      options = this.optionsService.get();
      options.headers = options.headers.set(FORM_ID_HEADER, formId.toString());
    }

    return this.http.get<TicketUpdate>(endpoint, TicketUpdate, options);
  }

  public getTicketCharges(ticketUpdate: TicketUpdate): Observable<OrderCalculatedPrices> {
    const endpoint = this.config.getServerUri() + API_ENDPOINT + `/calculate-charges`;

    return this.http.post<OrderCalculatedPrices>(endpoint, ticketUpdate, OrderCalculatedPrices);
  }

  public getTicketChargesV2(
    requestData: CalculateScheduleLoadChargesRequest
  ): Observable<OrderCalculatedPrices> {
    const endpoint = this.config.getServerUri() + API_ENDPOINT_V2 + `/calculate-charges`;

    return this.http.post<OrderCalculatedPrices>(endpoint, requestData, OrderCalculatedPrices);
  }

  public getBatchWeights(ticketId: number): Observable<BatchWeight[]> {
    const endpoint = this.config.getServerUri() + API_ENDPOINT + `/${ticketId}/batch-weights`;

    return this.http.getList<BatchWeight>(endpoint, BatchWeight);
  }

  public batchEditTickets(data: BatchEditTicket): Observable<BooleanResponse> {
    const endpoint = this.config.getServerUri() + API_ENDPOINT + `/batch-edit-tickets`;

    return this.http.patch<BooleanResponse>(endpoint, data, BooleanResponse, undefined, true);
  }

  public sendTicketModifiedEvent(ticketProducts: TicketUpdateData): void {
    this._ticketModifiedSubject.next(ticketProducts);
  }

  public emitTicketSaveOccurred(ticketUpdate: TicketUpdate): void {
    this._ticketSavedEventSubject.next(ticketUpdate);
  }

  public emitTicketVoidOccurred(ticket: Ticket): void {
    const obj = new TicketVoidEvent();
    obj.ticketId = ticket.id;
    obj.orderId = ticket.orderId;
    obj.scheduleId = ticket.scheduleId;
    this._ticketVoidedEventSubject.next(obj);
  }

  public emitEditTicketFormClearOccurred(): void {
    this._editTicketFormClearEvent.next();
  }

  public patchTicket(
    ticketId: number,
    data: TicketPatch,
    formId?: number
  ): Observable<BooleanResponse> {
    // currently only updates ticket products - may rename/refactor endpoint
    const endpoint = this.config.getServerUri() + API_ENDPOINT + `/${ticketId}/update-tpl-status`;

    let options;

    if (formId) {
      options = this.optionsService.get();
      options.headers = options.headers.set(FORM_ID_HEADER, formId.toString(10));
    }

    return this.http.patch<BooleanResponse>(endpoint, data, BooleanResponse, options, true);
  }

  public printTicketV2(
    ticketId: number,
    reprintFlag: boolean,
    requestId: string,
    selectedPrinter?: string,
    formId?: number
  ): Observable<TicketPrintReply> {
    const endpoint = this.config.getServerUri() + API_ENDPOINT + `/atps-print-ticket`;

    const data = new TicketPrintRequest(ticketId, reprintFlag, requestId, selectedPrinter);

    let options;

    if (formId) {
      options = this.optionsService.get();
      options.headers = options.headers.set(FORM_ID_HEADER, formId.toString(10));
    }

    return this.http.post<TicketPrintReply>(endpoint, data, TicketPrintReply, undefined, true);
  }

  public printTicketGroup(
    printData: TicketPrintGroupOptions
  ): Observable<SearchResult<TicketPrintGroupResponse>> {
    const endpoint = this.config.getServerUri() + API_ENDPOINT + `/print-ticket-group`;

    return this.http.postAny(endpoint, printData, undefined, true);
  }

  public updateTicket(data: TicketUpdate): Observable<TicketUpdate> {
    data.ticket = Object.assign(new Ticket(), data.ticket);

    const endpoint = this.config.getServerUri() + API_ENDPOINT + `/${data.ticket.id}`;

    return this.http.put<TicketUpdate>(endpoint, data, TicketUpdate, undefined, true);
  }

  public getTicketSalesTax(id: number): Observable<Array<SalesTax>> {
    const endpoint = this.config.getServerUri() + API_ENDPOINT + `/${id}/sales-tax`;

    return this.http.getList<SalesTax>(endpoint, SalesTax);
  }

  public getOrderIdFromTicket(ticketId: number): Observable<TicketOrderId> {
    const endpoint = this.config.getServerUri() + API_ENDPOINT + `/${ticketId}/order-id`;

    return this.http.get<TicketOrderId>(endpoint, TicketOrderId);
  }

  public reloadTruck(id: number): Observable<BooleanResponse> {
    const endpoint = this.config.getServerUri() + API_ENDPOINT + `/${id}/reload-truck`;

    return this.http.patch<BooleanResponse>(endpoint, undefined, BooleanResponse, undefined, true);
  }

  public removeProductFromTickets(
    productId: number,
    ticketIds: number[],
    formId?: number
  ): Observable<TicketProductDeleteResponse> {
    let options;

    if (formId) {
      options = this.optionsService.get();
      options.headers = options.headers.set(FORM_ID_HEADER, formId.toString(10));
    }

    const params = new URLSearchParams();
    if (ticketIds.length > 0) {
      params.set('ticketIds', ticketIds.join(',').toString());
    }

    const endpoint =
      this.config.getServerUri() +
      API_ENDPOINT +
      `/ticket-product-list/${productId}?` +
      params.toString();

    return this.http.delete<TicketProductDeleteResponse>(
      endpoint,
      TicketProductDeleteResponse,
      options,
      true
    );
  }
}

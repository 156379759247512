import { AgRendererComponent } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { ISelectable } from '@app/shared/models/selectable.interface';
import { SelectHeaderCheckboxTypes } from '@app/shared/models/select-header-checkbox-types.enum';

@Component({
  selector: 'acs-inquiry-header-checkbox',
  templateUrl: './select-header-checkbox.component.html',
  styles: [
    `
      :host {
        display: flex;
      }
    `
  ]
})
export class SelectHeaderCheckboxComponent implements AgRendererComponent {
  public checkboxChecked = false;
  public checkboxDisabled: boolean;
  public params: any;

  private items: ISelectable[] | any[] = [];
  private selectedKey = 'selected';

  constructor() {}

  public agInit(params: any): void {
    this.params = params;

    switch (this.params.column.colId) {
      case SelectHeaderCheckboxTypes.Invoice_Inquiry_Select:
        this.items = params.context.componentParent.invoiceInquries;
        break;
      case SelectHeaderCheckboxTypes.Ticket_Inquiry_Select:
        this.items = params.context.componentParent.ticketInqueries;
        break;
      case SelectHeaderCheckboxTypes.Flag_Invoice_Select:
        this.items = params.context.componentParent.orders;
        this.selectedKey = 'invoicedFlag';
        break;
      case SelectHeaderCheckboxTypes.Batch_Edit_Ticket_Select:
        this.items = params.context.componentParent.tableData;
        break;
    }

    if (this.items.length) {
      this.checkboxDisabled = false;
      if (this.items.every((y) => y[this.selectedKey] === true)) {
        this.checkboxChecked = true;
      }
    } else {
      this.checkboxDisabled = true;
      this.checkboxChecked = false;
    }
  }

  public refresh(params): boolean {
    this.params = params;
    return true;
  }

  public selectAll(): void {
    this.checkboxChecked = !this.checkboxChecked;
    this.params.context.componentParent.handleSelectAllRequest(this.checkboxChecked);
  }
}

import { AUJS_FORMS } from '@app/forms/shared/aujs-forms';
import { AuthorizationService } from '@app/security/shared/authorization.service';
import { FORM_PERMISSIONS } from '@app/security/shared/form-permission.model';
import { Job } from '@app/jobs/models/job.model';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Should return true if user should be able to edit a job
 * based on the provided job object and form permissions
 */
@Pipe({
  name: 'jobEditPermission'
})
export class JobEditPermissionPipe implements PipeTransform {
  constructor(private authorizationService: AuthorizationService) {}

  transform(job: Job, _args: any): any {
    const formPermission = this.authorizationService.getFormPermission(AUJS_FORMS.FormJob.id)
      .permission;
    const hasPermission =
      formPermission === FORM_PERMISSIONS.FullWithPrices ||
      formPermission === FORM_PERMISSIONS.FullNoPrices;

    return job.id && !job.lockedFlag && hasPermission;
  }
}

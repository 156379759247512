import { Component, Inject, ViewChild } from '@angular/core';
import {
  GenericTableColumn,
  GenericTableData
} from '@app/shared/dialogs/generic-table-dialog/generic-table-data.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';

@Component({
  selector: 'acs-generic-table-dialog',
  styles: ['mat-row:hover { cursor: pointer; }', ':host { overflow: auto}'],
  templateUrl: './generic-table-dialog.component.html'
})
export class GenericTableDialogComponent {
  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  public currentIndex = -1;
  public dataSource = new MatTableDataSource();
  public columnData: GenericTableColumn[];
  public columnHeaders: string[];
  public showNewBtn: boolean;
  private subscriptions: Subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GenericTableData,
    private dialogRef: MatDialogRef<GenericTableDialogComponent>
  ) {
    this.columnData = this.data.columns;
    this.columnHeaders = this.data.columns.map((x) => x.displayValue);
    this.showNewBtn = this.data.showNewBtn;
  }

  ngAfterViewInit(): void {
    this.dataSource = new MatTableDataSource(this.data.items);
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = this.sortDataAccessor;

    setTimeout(() => {
      this.subscriptions.add(
        this.sort.sortChange.subscribe(() => {
          this.currentIndex = -1;
        })
      );
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public tableContentChanged(): void {
    try {
      if (!this.data.columnStyles?.length) {
        return;
      }

      for (const currentStyle of this.data.columnStyles) {
        const elementList = document.getElementsByClassName(currentStyle.name);
        for (let j = 0; j < currentStyle.properties.length; j++) {
          const currentProperty = currentStyle.properties[j];
          const currentValue = currentStyle.values[j];
          for (let k = 0; k < elementList.length; k++) {
            // ? check for null
            (elementList.item(k) as HTMLElement).style[currentProperty] = currentValue;
          }
        }
      }
    } catch (error) {
      // don't want to mess up table rendering if an error occurs
      console.error(error);
    }
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public onItemSelected(item: any): void {
    this.dialogRef.close(item);
  }

  public sendItem(item: any): void {
    this.onItemSelected(item);
  }

  public clickedNew(): void {
    this.dialogRef.close(true);
  }

  public lookupAndSend(recordId: any) {
    if (this.data.pkField) {
      const item = this.data.items.find((x) => x[this.data.pkField] === parseInt(recordId, 10));
      this.onItemSelected(item);
    }
  }

  private sortDataAccessor = (data, sortheader: string) => {
    const objectKeyForSort = this.data.columns.find((x) => x.displayValue === sortheader)
      ?.objectValue;

    if (objectKeyForSort) {
      return data[objectKeyForSort];
    }

    return 0;
  };
}

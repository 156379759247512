import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { KEY_CODE } from '@app/shared/constants/key-code.const';

const selectedClass = 'selected';
const flashActiveClass = 'flash';

@Directive({
  selector: '[acsArrowKeyNavigator]'
})
export class ArrowKeyNavigatorDirective implements AfterViewInit, OnChanges {
  @Input() currentIndex = 0;
  @Input() ignoreFirstElement = false;
  @Input() ignoreLastElement = false;
  @Input() elementsToSkip: number[] = [];
  @Output() rowSelected: EventEmitter<any> = new EventEmitter();
  @Output() currentIndexChange: EventEmitter<number> = new EventEmitter();

  private currentElement: HTMLElement;
  private minIndex = 0;

  constructor(private el: ElementRef) {
    this.currentElement = this.el.nativeElement.children[this.currentIndex];
  }

  @HostListener('keydown', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === KEY_CODE.UP_ARROW) {
      this.stopDefaultActions(event);
      this.goToPreviousElement();
    }

    if (event.key === KEY_CODE.DOWN_ARROW) {
      this.stopDefaultActions(event);
      this.goToNextElement();
    }

    if (event.key === KEY_CODE.ENTER) {
      this.stopDefaultActions(event);
      const currentElementAttr = this.currentElement?.attributes['elementId'];

      if (currentElementAttr) {
        this.rowSelected.emit(currentElementAttr.value);
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.ignoreFirstElement === true) {
      this.minIndex = 1;
    }
  }

  ngOnChanges(onChangesObj): void {
    if (
      onChangesObj.currentIndex &&
      onChangesObj.currentIndex.currentValue !== onChangesObj.currentIndex.previousValue
    ) {
      this.removeFocusFromAllRows();
      if (this.currentIndex === -1) {
        this.setFocusToFirstRow();
      } else {
        this.currentElement = this.el.nativeElement.children[this.currentIndex];
        this.addSelectedClassAndFocus();
      }
    }
  }

  public setFocusToFirstRow(): void {
    setTimeout(() => {
      if (this.el.nativeElement.children.length > 1) {
        if (this.currentIndex === -1) {
          this.currentIndex = this.ignoreFirstElement === true ? 1 : 0;
          this.currentElement = this.el.nativeElement.children[this.currentIndex];
          this.currentIndexChange.emit(this.currentIndex);
          this.addSelectedClassAndFocus();
        }
      }
    });
  }

  public removeFocusFromAllRows(): void {
    if (this.currentElement) {
      this.currentElement.classList.remove(selectedClass);
    }
  }

  private stopDefaultActions(event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  private removeSelectionClasses(): void {
    this.currentElement.classList.remove(flashActiveClass);
    this.currentElement.classList.remove(selectedClass);
  }

  private addSelectedClassAndFocus(): void {
    this.currentElement.classList.add(selectedClass);
    this.currentElement.focus();
  }

  /*********************************
   * Navigation methods
   *********************************/

  private goToPreviousElement(): void {
    if (this.currentIndex > this.minIndex) {
      this.removeSelectionClasses();

      let tempIndex = this.currentIndex;
      while (this.elementsToSkip.find((e) => e === tempIndex - 1)) {
        tempIndex--;
      }
      this.currentIndex = tempIndex - 1;
      if (this.currentIndex < 1) {
        this.currentIndex = 1;
      }
      this.addSelectedClassAndFocus();
      this.currentIndexChange.emit(this.currentIndex);
    } else {
      this.flashElement();
    }
  }

  private goToNextElement(): void {
    if (
      this.currentIndex <
      this.el.nativeElement.children.length - (this.ignoreLastElement ? 2 : 1)
    ) {
      this.removeSelectionClasses();

      let tempIndex = this.currentIndex;
      while (this.elementsToSkip.find((e) => e === tempIndex + 1)) {
        tempIndex++;
      }
      this.currentIndex = tempIndex + 1;

      if (this.currentIndex >= this.el.nativeElement.children.length) {
        this.flashElement();
      } else {
        this.addSelectedClassAndFocus();
        this.currentIndexChange.emit(this.currentIndex);
      }
    } else {
      this.flashElement();
    }
  }

  private flashElement(): void {
    this.currentElement.classList.add(flashActiveClass);
    setTimeout(() => {
      this.currentElement.classList.remove(flashActiveClass);
    }, 300);
  }
}

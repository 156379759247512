import { AuthorizationService } from '@app/security/shared/authorization.service';
import { Directive, ElementRef, Inject, Input, OnInit, Optional, Renderer2 } from '@angular/core';
import { DocumentRefService } from '@app/shared/services/browser/document-ref.service';
import { FORM_PERMISSIONS } from '@app/security/shared/form-permission.model';
import { FormService } from '@app/forms/shared/forms.service';
import { Logger } from '@app/logger/logger';
import { MatFormField, MAT_FORM_FIELD } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { NgControl } from '@angular/forms';
import { hasValue } from '@app/shared/utilities/comparison-helpers.utility';

/**
 * Use this directive on various form elements to hide or disable them based on form permissions.
 * The valid element types are <button>, <mat-form-field>, and any input type element that is a FormControl.
 *
 * @param formIdOverride The form Id to use when gathering permissions. Ignores the current form set in the FormService.
 * @param useButtonOverrideText Whether to append text to any button place holder elements. Only used when ElementRef is a button.
 * @param keepButtonOnReadonly If the directive is on a button this determines whether it is replaced by a fake or not. Could be
 * set to true when the button would open a new form.
 * Notes:
 *
 * ! Mat-select and mat-input fields cannot be directly removed so we have to use the MatFormField reference to remove them
 *
 * ! Currently if the directive is on a button we remove it and add a fake 'dummy' element. This prevents savvy users from enabling the element.
 */
@Directive({ selector: '[acsPermissionHandler]' })
export class PermissionHandlerDirective implements OnInit {
  @Input() keepButtonOnReadonly = false;
  @Input() formIdOverride: number; // use this for dialog forms? not ideal...
  @Input() useButtonOverrideText: string; // may need to extend this in the future

  private formPermissions;
  private allowedCustomComponentTags: string[] = ['acs-credit-calculation'];

  constructor(
    @Optional() private baseControl: NgControl,
    @Optional() @Inject(MAT_FORM_FIELD) private formField: MatFormField,
    @Optional() public selectControl: MatSelect,
    private authorizationService: AuthorizationService,
    private documentService: DocumentRefService,
    private el: ElementRef,
    private formService: FormService,
    private logger: Logger,
    private renderer: Renderer2
  ) {
    if (
      !hasValue(this.baseControl) &&
      !hasValue(this.formField) &&
      !(this.el.nativeElement instanceof HTMLButtonElement) &&
      !this.isAllowedCustomComponent()
    ) {
      this.logger.warn(
        'acsPermissionHandler directive can only be used on form controls, button elements, allowed custom-components, or mat-form-field elements.'
      );
    }
  }

  ngOnInit(): void {
    const formId = this.getFormId();

    if (formId) {
      this.formPermissions = this.authorizationService.getFormPermission(formId);
      if (this.el.nativeElement instanceof HTMLButtonElement) {
        this.handleButton();
      } else if (
        this.formPermissions.permission === FORM_PERMISSIONS.ReadOnlyNoPrices ||
        this.formPermissions.permission === FORM_PERMISSIONS.FullNoPrices
      ) {
        if (hasValue(this.formField)) {
          this.removeMatFormField();
        } else if (hasValue(this.baseControl)) {
          this.removeFormControl();
        }
      } else if (
        this.formPermissions.permission === FORM_PERMISSIONS.FullWithReadOnlyPrices ||
        this.formPermissions.permission === FORM_PERMISSIONS.ReadOnlyWithPrices
      ) {
        if (hasValue(this.formField)) {
          this.disableMatFormField();
        } else if (hasValue(this.baseControl)) {
          // called on check boxes since they aren't used in mat-form-fields
          this.disableFormControl();
        }
      }
    } else {
      console.warn('Form Id not set');
    }

    if (this.isAllowedCustomComponent()) {
      this.handleCustomComponent();
    }
  }

  private getFormId(): number {
    if (this.formIdOverride) {
      return this.formIdOverride;
    }

    if (this.formService.formOverrideId) {
      return this.formService.formOverrideId;
    }

    return this.formService.getCurrentForm()?.id;
  }

  private handleCustomComponent(): void {
    if (
      this.formPermissions.permission === FORM_PERMISSIONS.FullNoPrices ||
      this.formPermissions.permission === FORM_PERMISSIONS.ReadOnlyNoPrices ||
      this.formPermissions.permission === FORM_PERMISSIONS.None
    ) {
      this.renderer.removeChild(this.el.nativeElement.parentNode, this.el.nativeElement);
    }
  }

  private isAllowedCustomComponent(): boolean {
    const tagName = this.el.nativeElement.tagName.toLowerCase();
    return this.allowedCustomComponentTags.includes(tagName);
  }

  private removeMatFormField(): void {
    if (this.selectControl || this.baseControl) {
      this.documentService.removeElement(this.formField._elementRef.nativeElement);
    } else {
      this.documentService.removeElement(this.el.nativeElement);
    }
  }

  private disableMatFormField(): void {
    if (hasValue(this.selectControl)) {
      this.selectControl.ngControl.control.disable({ emitEvent: false });
    } else if (hasValue(this.baseControl)) {
      this.disableFormControl();
    }

    this.documentService.applyReadOnlyStylesToElement(this.el.nativeElement);
  }

  private removeFormControl(): void {
    // ! not working properly with mat-select
    this.documentService.hideElement(this.el.nativeElement);
  }

  private disableFormControl(): void {
    this.baseControl.control.disable({ emitEvent: false });
  }

  private handleButton(): void {
    if (
      this.formPermissions.permission === FORM_PERMISSIONS.ReadOnlyNoPrices ||
      this.formPermissions.permission === FORM_PERMISSIONS.ReadOnlyWithPrices
    ) {
      if (!this.keepButtonOnReadonly) {
        this.createFakeElement();
      }
    }

    if (this.formPermissions.permission === FORM_PERMISSIONS.None) {
      this.documentService.removeElement(this.el.nativeElement);
    }
  }

  private createFakeElement() {
    const elementCopy = this.documentService.copyHTMLNode(this.el.nativeElement);

    if (this.useButtonOverrideText) {
      elementCopy.appendChild(document.createTextNode(this.useButtonOverrideText));
    }

    this.documentService.applyDisableStylesToElement(elementCopy);
    this.el.nativeElement.parentNode.append(elementCopy);
    this.documentService.removeElement(this.el.nativeElement);
  }
}

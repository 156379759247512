import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[acsNoLeadingSpace]'
})
export class NoLeadingSpaceDirective {
  constructor(private el: ElementRef<HTMLInputElement>) {}

  @HostListener('keydown', ['$event'])
  preventLeadingSpace(event: KeyboardEvent) {
    if (event.code === 'Space' && this.isInputEmptyOrStartsWithSpace()) {
      event.preventDefault();
    }
  }

  @HostListener('keyup')
  trimLeadingWhitespace() {
    this.el.nativeElement.value = this.el.nativeElement.value.replace(/^\s+/, '');
  }

  private isInputEmptyOrStartsWithSpace(): boolean {
    const { value } = this.el.nativeElement;
    return !value || value.startsWith(' ');
  }
}

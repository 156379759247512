import { Moment } from 'moment';
import { convertIso8601ToDate } from '@app/shared/utilities/date-helpers.utility';

export class SimpleTicket {
  id: number;
  loadNumber: number;
  number: number;
  object: string;
  plantName: string;
  preparedForBillingFlag: boolean;
  statusDescription: string;
  truckNumber: string;

  private _print: Moment;
  get print(): Moment {
    return this._print;
  }
  set print(val) {
    this._print = convertIso8601ToDate(val);
  }

  private _ticketTime: Moment;
  get ticketTime(): Moment {
    return this._ticketTime;
  }
  set ticketTime(val) {
    this._ticketTime = convertIso8601ToDate(val);
  }
}

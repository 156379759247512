import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Logger } from '@app/logger/logger';
import { hasValue } from '@app/shared/utilities/comparison-helpers.utility';

/**
 * Directive for creating 'touch and hold' events for mobile and
 * touch devices. If a user holds a touch for 1 second the function
 * passed in as 'acsTouchAndHold' will be ran.
 *
 * This will act as a stand in for keyup.enter functions on touch devices.
 */
@Directive({ selector: 'input[acsTouchAndHold]' })
export class TouchAndHoldDirective {
  @Input() acsTouchAndHold: Function;

  private clickAndHoldTimer: NodeJS.Timeout;

  constructor(private el: ElementRef, private logger: Logger) {}

  @HostListener('touchstart', ['$event'])
  beginClickAndHoldTimer(): void {
    this.clickAndHoldTimer = setTimeout(() => {
      if (hasValue(this.acsTouchAndHold) && this.acsTouchAndHold instanceof Function) {
        this.acsTouchAndHold();
      } else {
        this.logger.log(
          'TouchAndHoldDirective: touchEventToRun was null or not a function and could not be ran'
        );
      }
      this.clearClickAndHoldTimer();
    }, 1000);
  }

  @HostListener('touchend', ['$event'])
  public clearClickAndHoldTimer(event?: MouseEvent): void {
    clearTimeout(this.clickAndHoldTimer);

    event?.preventDefault();

    this.el.nativeElement.focus();
  }
}

import { AUSnackBarService } from '@app/notifications/au-snackbar.service';
import { Component, Inject } from '@angular/core';
import { DispatchService } from '@app/dispatcher/dispatch.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PrintDestination } from '@app/shared/models/print-destination.model';
import {
  PrintOptions,
  PrintType,
  TicketPrintGroupOptions,
  TicketPrintOptions
} from '@app/shared/dialogs/print-dialog/models/print-options.model';
import { PrinterService } from '@app/shared/services/printer/printer.service';
import { SearchResult } from '@app/shared/models/search-result.model';
import { TicketingService } from '@app/ticketing/shared/ticketing.service';
import { TicketPrintGroupResponse } from '@app/ticketing/models/printing/ticket-print-group-response.model';
import { getIdentifierForIterableItem } from '@app/shared/utilities/trackby.utility';
import { hasValue } from '@app/shared/utilities/comparison-helpers.utility';

@Component({
  selector: 'acs-print-ticket-dialog',
  templateUrl: './print-options-dialog.component.html'
})
export class PrintOptionsDialogComponent {
  public getIdentifierForIterableItem = getIdentifierForIterableItem;
  public printDestinations = Array<PrintDestination>();
  public printOptionsForm = new UntypedFormGroup({
    copies: new UntypedFormControl(1),
    orientation: new UntypedFormControl(1),
    printerName: new UntypedFormControl()
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: PrintOptions,
    private auSnackBar: AUSnackBarService,
    private dialogRef: MatDialogRef<PrintOptionsDialogComponent>,
    private dispatchService: DispatchService,
    private printerService: PrinterService,
    private ticketingService: TicketingService
  ) {}

  ngOnInit(): void {
    this.getPrinterDestinations();
  }

  public print(): void {
    switch (this.data.printType) {
      case PrintType.Ticket:
        if (this.data instanceof TicketPrintOptions) {
          this.printTicket();
        } else if (this.data instanceof TicketPrintGroupOptions) {
          this.printTicketGroup();
        } else {
          throw Error('Invalid Object Type for PrintType.Ticket');
        }
        break;
      default:
        console.error('Print type required');
        break;
    }
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public getPrinterDestinations(): void {
    this.printerService.getPrinters().subscribe((results: PrintDestination[]) => {
      if (results.length > 0) {
        this.printDestinations = results;
      } else {
        this.auSnackBar.openSimple(false, 'No printers currently available');
      }
    });
  }

  public isPrintDisabled(): boolean {
    return !hasValue(this.printOptionsForm.get('printerName').value);
  }

  private printTicketGroup(): void {
    const ticketPrintGroupOptions = this.data as TicketPrintGroupOptions;
    this.dispatchService.getConnectionIdAsync().subscribe((connectionId: string) => {
      ticketPrintGroupOptions.requestId = connectionId;
      ticketPrintGroupOptions.ticketPrintQueue = this.printOptionsForm.get('printerName').value;
      this.ticketingService.printTicketGroup(ticketPrintGroupOptions).subscribe(
        (result: SearchResult<TicketPrintGroupResponse>) => {
          this.handlePrintTicketGroupResponse(result.data);
        },
        (error) => {
          this.auSnackBar.openSimple(false, 'Ticket print failed', 0, error.message);
        }
      );
    });
  }

  private printTicket(): void {
    const ticketPrintOptions = this.data as TicketPrintOptions;
    this.dispatchService.getConnectionIdAsync().subscribe((connectionId: string) => {
      this.ticketingService
        .printTicketV2(
          ticketPrintOptions.ticketId,
          ticketPrintOptions.reprint,
          connectionId,
          this.printOptionsForm.get('printerName').value
        )
        .subscribe(
          (response) => {
            if (response.successFlag) {
              this.auSnackBar.openSimple(true, response.responseMessage);
            } else {
              this.auSnackBar.openSimple(false, 'Ticket print failed', 0, response.responseMessage);
            }
          },
          (error) => {
            this.auSnackBar.openSimple(false, 'Ticket Print Failed', 0, error.message);
          }
        );
    });
  }

  private handlePrintTicketGroupResponse(responseData: Array<TicketPrintGroupResponse>): void {
    const failedPrints = responseData.filter((x) => !x.Sent);
    if (failedPrints.length) {
      const failedTicketNumbers = failedPrints.map((x) => x.TicketNumber);
      const failedTicketNumberString = failedTicketNumbers.join(', ');
      this.auSnackBar.openSimple(
        false,
        `Ticket print failed for ticket(s) with number: ${failedTicketNumberString}`
      );
    } else {
      this.auSnackBar.openSimple(true, 'Tickets sent to printer');
    }
  }
}

import { AfterContentInit, Directive, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { UntypedFormControl, NgControl } from '@angular/forms';
import { PhoneNumberMaskValidatorService } from '@app/shared/services/phone-number-mask-validator.service';
import { Subscription } from 'rxjs';

const generalPhoneFieldKeys = [
  'Backspace', // BACKSPACE
  'Tab', // TAB
  'Enter', // ENTER
  'Escape', // ESCAPE
  'Delete', // DELETE'
  'ArrowDown',
  'End',
  'Home',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp'
];

@Directive({ selector: '[acsPhoneNumberMask]' })
export class PhoneNumberMaskDirective implements AfterContentInit, OnDestroy {
  private el: HTMLInputElement;
  private formControl: UntypedFormControl;
  private formChangeSub = new Subscription();
  private prevLength = 0;

  constructor(
    private baseControl: NgControl,
    private elementRef: ElementRef,
    private phoneNumberMaskValidator: PhoneNumberMaskValidatorService
  ) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('keydown', ['$event', '$event.target.value'])
  onKeyUp(event, value: string): void {
    this.formControl?.markAsDirty();

    if (
      generalPhoneFieldKeys.indexOf(event.key) === -1 &&
      this.phoneNumberMaskValidator.isMaskAvailable
    ) {
      const strippedValue = this.phoneNumberMaskValidator.stripMask(value);
      if (strippedValue.length >= this.phoneNumberMaskValidator.rawMaskLength) {
        const textSelection = document.getSelection()?.toString();
        if (!Boolean(textSelection?.trim())) {
          event.preventDefault();
          return;
        }
      }
    }
  }

  ngAfterContentInit(): void {
    this.formControl = this.baseControl.control as UntypedFormControl;

    if (this.phoneNumberMaskValidator.isMaskAvailable) {
      this.formChangeSub.add(
        this.formControl.valueChanges.subscribe((val) => {
          this.maskPhoneValueAndSetToObjects(val);
        })
      );

      if (this.el.value) {
        this.maskPhoneValueAndSetToObjects(this.el.value);
      }
    }
  }

  ngOnDestroy(): void {
    this.formChangeSub.unsubscribe();
  }

  private maskPhoneValueAndSetToObjects(value: string): void {
    const strippedValue = this.phoneNumberMaskValidator.stripMask(value);
    const isDelete = this.prevLength >= strippedValue.length;
    const maskedValue = this.phoneNumberMaskValidator.addMask(value, isDelete);
    // handles edge case where user changes phone mask
    const newStrippedVal = this.phoneNumberMaskValidator.stripMask(maskedValue);
    this.prevLength = strippedValue.length;
    this.el.value = maskedValue;

    this.formControl.patchValue(newStrippedVal, { emitModelToViewChange: false, emitEvent: false });
    this.checkIfFormFieldValid(maskedValue);
  }

  private checkIfFormFieldValid(maskedValue: string): void {
    if (!this.phoneNumberMaskValidator.isPhoneNumberValid(maskedValue, true)) {
      this.formControl.setErrors({ invalid: true });

      // will apply invalid style to the input on page load
      if (this.el !== document.activeElement) {
        this.formControl.markAsTouched();
      }
    } else {
      this.formControl.setErrors(null);
    }
  }
}

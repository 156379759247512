import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[acsFocus]'
})
export class FocusDirective implements OnChanges {
  @Input() acsFocus: boolean;
  @Input() acsCursorPosition: 'start' | 'end' | 'select' = 'select';

  constructor(private el: ElementRef) {}

  // IMPORTANT!!!
  // this has not been test because the core app is still ng1
  // and there is no way to downgrade a directive for ng1
  public ngOnChanges(changes: SimpleChanges): void {
    const focus = changes['acsFocus'].currentValue;
    let inputElement: HTMLInputElement;
    setTimeout(() => {
      if (focus === true && this.el.nativeElement !== undefined) {
        if (this.el.nativeElement.tagName !== 'INPUT') {
          inputElement = this.el.nativeElement.getElementsByTagName('input')[0];
        } else {
          inputElement = this.el.nativeElement;
        }

        setTimeout(() => {
          inputElement.focus();

          switch (this.acsCursorPosition) {
            case 'start':
              inputElement.selectionStart = 0;
              inputElement.selectionEnd = 0;
              break;
            case 'end':
              const length = inputElement.value.length;
              inputElement.selectionStart = length;
              inputElement.selectionEnd = length;
              break;
            case 'select':
            default:
              inputElement.select(); // Default behavior
              break;
          }
        });
      }
    }, 200);
  }
}

import { IForm, FormContructorParams, Form } from '@app/forms/form.model';
import { ILegacy } from '@app/legacy/legacy.interface';
import { Injectable } from '@angular/core';
import { LegacyService } from '@app/legacy/legacy.service';
import { Logger } from '@app/logger/logger';
import { Observable, from, of, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { WindowService } from '@app/shared/services/browser/window.service';
import { AUJS_FORMS } from '@app/forms/shared/aujs-forms';
import { hasValue } from '@app/shared/utilities/comparison-helpers.utility';

const lgSfx = '\t(form.service)';

export enum OpenFormTarget {
  SameWindow = 1,
  NewWindow = 2,
  NewTab = 3
}

@Injectable({
  providedIn: 'root'
})
export class FormService {
  public formChanged = new Subject<number>();
  public formDirtyStatusChanged = new Subject<void>();

  private _formOverrideIdQ: number[] = [];
  private _formOverrideId: number;

  private legacy: ILegacy;
  private _currentForm: Form;

  private set currentForm(form) {
    this._currentForm = form;
    this.formChanged.next(form.id);
    this.formOverrideId = undefined;
    this._formOverrideIdQ = [];
  }

  private get currentForm(): Form {
    return this._currentForm;
  }

  public get currentFormId(): number {
    return this._formOverrideId || this.currentForm?.id;
  }

  public get formOverrideIdQ(): number[] {
    return this._formOverrideIdQ;
  }

  public get formOverrideId(): number {
    return this._formOverrideId;
  }

  // ! Note: may need to rework this into a function with parameters to control behavior
  public set formOverrideId(val) {
    if (!hasValue(val) && this._formOverrideIdQ.length) {
      this._formOverrideId = this._formOverrideIdQ.pop();
    } else {
      if (hasValue(val) && this._formOverrideId) {
        this._formOverrideIdQ.push(this._formOverrideId);
      }

      this._formOverrideId = val;
    }
  }

  constructor(
    private router: Router,
    private legacyService: LegacyService,
    private log: Logger,
    private windowService: WindowService
  ) {
    this.legacy = this.legacyService.service;
  }

  public getFormWithParams(params: FormContructorParams): IForm {
    return new Form(params);
  }

  public openForm(form: IForm, target = OpenFormTarget.SameWindow): Observable<boolean> {
    this.log.log(`Opening form: ${form.enumId} ${lgSfx} ${target}`);

    if (form.legacy === true) {
      this.legacy.openForm(form.enumId);
    } else if (typeof form.path !== 'undefined' && form.path !== null && form.path.length > 0) {
      this.log.log(`Opening form path ${form.path}`);

      switch (target) {
        case OpenFormTarget.SameWindow: {
          this.currentForm = form;
          return from(this.router.navigate([form.path]));
        }

        case OpenFormTarget.NewTab: {
          this.openFormInNewTab(form);
          return of(true);
        }

        case OpenFormTarget.NewWindow: {
          this.log.warn('OpenFormTarget.NewWindow is not implemented');
          return from(this.router.navigate([form.path]));
        }

        default: {
          this.log.error(
            `Unable to open requested form (${form.id}); loading in SameWindow ${lgSfx}`
          );
          return from(this.router.navigate([form.path]));
        }
      }
    } else {
      this.log.error(`Unable to open requested form (${form.id}) ${lgSfx}`);
    }
  }

  public getCurrentForm(): Form {
    return this.currentForm;
  }

  public setCurrentFormByEnumId(enumId: string): void {
    for (const property in AUJS_FORMS) {
      if (enumId === AUJS_FORMS[property].enumId) {
        this.currentForm = AUJS_FORMS[property];
        this.log.info('form service found form from enumid', this.currentForm);
        return;
      }
    }
  }

  public setCurrentForm(id: number, path: string): void {
    for (const property in AUJS_FORMS) {
      if (id) {
        if (id === AUJS_FORMS[property].id) {
          this.currentForm = AUJS_FORMS[property];
          this.log.info('form service found form from id', this.currentForm);
          return;
        }
      } else if (path && AUJS_FORMS[property].path) {
        if (path.includes(AUJS_FORMS[property].path)) {
          this.currentForm = AUJS_FORMS[property];
          this.log.info('form service found form from path', this.currentForm);
          return;
        }
      }
    }
  }

  public setCurrentFormFromPath(path: string, overwrite = false, exactMatchFalse = false): void {
    if (!overwrite && this.currentForm) {
      return;
    }

    if (!exactMatchFalse) {
      for (const property in AUJS_FORMS) {
        if (AUJS_FORMS[property].path && path.includes(AUJS_FORMS[property].path)) {
          this.currentForm = AUJS_FORMS[property];
          this.log.info('form service found form from path', this.currentForm);
          return;
        }
      }
    } else {
      for (const property in AUJS_FORMS) {
        if (AUJS_FORMS[property].path && path === AUJS_FORMS[property].path) {
          this.currentForm = AUJS_FORMS[property];
          this.log.info('form service found form from path', this.currentForm);
          return;
        }
      }
    }

    this.log.info('WARNING: No form found');
  }

  public setFormStatus(isDirty: boolean): void {
    if (this.currentForm) {
      this.currentForm.isDirty = isDirty;
      this.formDirtyStatusChanged.next();
    }
  }

  public GetFormById(id: number): Form {
    for (const property in AUJS_FORMS) {
      if (AUJS_FORMS[property].id === id) {
        return AUJS_FORMS[property];
      }
    }
  }

  private openFormInNewTab(form: IForm): void {
    // no legacy integragtion can open tabs
    if (!this.legacy.canOpenTabs) {
      this.windowService.openCurrentViewInNewTab(form);
      return;
    }

    this.legacy.openTab([form.path]);
  }
}

import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { KEY_CODE } from '@app/shared/constants/key-code.const';

const generalKeys = [
  'Backspace', // BACKSPACE
  'Tab', // TAB
  'Enter', // ENTER
  'Escape', // ESCAPE
  'Delete', // DELETE
  ':' // COLON
];

@Directive({
  selector: '[acsOnlyNumber]'
})
export class OnlyNumberDirective {
  @Input() allowCommas: boolean;
  @Input() allowDashes: boolean;
  @Input() allowDecimal = true;
  @Input() allowNegative = false;
  @Input() allowSpaces: boolean;

  constructor(private elementRef: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (
      generalKeys.indexOf(event.key) !== -1 ||
      // Allow: Ctrl keys
      ((event.ctrlKey || event.metaKey) &&
        (event.key === KEY_CODE.A ||
          event.key === KEY_CODE.C ||
          event.key === KEY_CODE.V ||
          event.key === KEY_CODE.X ||
          event.key === KEY_CODE.Z)) ||
      event.key === KEY_CODE.RIGHT_ARROW ||
      event.key === KEY_CODE.LEFT_ARROW ||
      event.key === KEY_CODE.HOME ||
      event.key === KEY_CODE.END
    ) {
      return;
    }

    // Allow user to type a dash for negative number if input is empty
    // or allow user to type in dash when allowDash is enabled for a range if the last character is not already a dash
    if (event.key === KEY_CODE.DASH && (this.allowNegative || this.allowDashes)) {
      const elementValue = String(this.elementRef.nativeElement.value);
      if (elementValue.length === 0 && this.allowNegative) {
        return;
      }

      if (
        this.allowDashes &&
        elementValue.length > 0 &&
        elementValue[elementValue.length - 1] !== KEY_CODE.DASH
      ) {
        return;
      }
    }

    // Allow decimal key press if allowDecimal is true and element doesn't already have decimal
    if (event.key === KEY_CODE.DECIMAL) {
      if (this.allowDecimal) {
        const elementValue = String(this.elementRef.nativeElement.value);
        if (elementValue.includes('.')) {
          event.preventDefault();
        }
        return;
      } else {
        event.preventDefault();
      }
    }

    // Allow comma key press if allowComma is true if the last character is not already a comma
    if (event.key === KEY_CODE.COMMA && this.allowCommas) {
      const elementValue = String(this.elementRef.nativeElement.value);
      if (elementValue.length > 0 && elementValue[elementValue.length - 1] !== KEY_CODE.COMMA) {
        return;
      }
    }

    // Allow space key press if allowSpace is true if the last character is not already a space
    if (this.allowSpaces && event.key === KEY_CODE.SPACE) {
      const elementValue = String(this.elementRef.nativeElement.value);

      if (elementValue.length > 0 && elementValue[elementValue.length - 1] !== KEY_CODE.SPACE) {
        return;
      }
    }

    // If it is NOT a number, stop the keypress
    if (!parseInt(event.key, 10) && parseInt(event.key, 10) !== 0) {
      event.preventDefault();
    }
  }
}

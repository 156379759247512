import { AuthorizationService } from '@app/security/shared/authorization.service';
import { FORM_PERMISSIONS } from '@app/security/shared/form-permission.model';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Use this pipe on elements in conjunction with *ngIf or [disabled].
 * Pass the form id and a permission type check value. If the associated
 * form for the user is NOT at the given type level it will return true
 * else false.
 *
 * Current Types:
 * - 0 -> none
 * - 1 -> read only
 * - else -> full
 */

@Pipe({
  name: 'formEditPermission'
})
export class FormPermissionPipe implements PipeTransform {
  constructor(private authorizationService: AuthorizationService) {}

  transform(formId: number, permissionTypeCheck = 0): any {
    const formPermission = this.authorizationService.getFormPermission(formId).permission;

    // None
    if (permissionTypeCheck === 0) {
      return formPermission !== FORM_PERMISSIONS.None;
    }

    // Read Only
    if (permissionTypeCheck === 1) {
      return (
        formPermission !== FORM_PERMISSIONS.ReadOnlyNoPrices &&
        formPermission !== FORM_PERMISSIONS.ReadOnlyWithPrices
      );
    }

    // Price Check
    if (permissionTypeCheck === 2) {
      return (
        formPermission !== FORM_PERMISSIONS.FullNoPrices &&
        formPermission !== FORM_PERMISSIONS.ReadOnlyNoPrices
      );
    }

    // ? not sure what this would be useful for
    return (
      formPermission !== FORM_PERMISSIONS.FullWithPrices &&
      formPermission !== FORM_PERMISSIONS.FullNoPrices &&
      formPermission !== FORM_PERMISSIONS.FullWithReadOnlyPrices
    );
  }
}

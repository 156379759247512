import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  Directive,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { hasValue } from '@app/shared/utilities/comparison-helpers.utility';
import { takeUntil } from 'rxjs/operators';

/**
 * The default viewport height assuming
 * enough elements exist to take up 200px
 * of space.
 */
const BASE_VIEWPORT_HEIGHT = 200;

/**
 * height of the items within the viewport. Based
 * on the default height of a mat-option element.
 */
const VIEWPORT_ITEM_HEIGHT = 48;

/**
 * Directive to be placed on elements that contain a child
 * CdkVirtualScrollViewport (cdk-virtual-scroll-viewport).
 *
 * Will properly set the height for the virtual viewport based on the
 * number of elements and ensures that it actually renders on focus.
 *
 * A reference to the CdkVirtualScrollViewport should be passed in to
 * the directive. We can't use ViewChildren or inject a reference to
 * it because it is typically rendered outside the parent element
 * using a CdkOverlay.
 */
@Directive({
  selector: '[acsVVPHeightCalc]'
})
export class VirtualViewportCalculatorDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input('acsVVPHeightCalc') virtualViewport: CdkVirtualScrollViewport;

  private onDestroyed$ = new Subject<void>();

  constructor(@Optional() private autoTrigger: MatAutocompleteTrigger) {}

  ngOnInit(): void {
    if (!hasValue(this.virtualViewport)) {
      throw Error('virtualViewport Input was null');
    }
  }

  ngAfterViewInit(): void {
    if (this.autoTrigger) {
      this.autoTrigger.autocomplete.options.changes
        .pipe(takeUntil(this.onDestroyed$))
        .subscribe(() => {
          this.recalcHeightEvent();
        });
    }
  }

  ngOnDestroy(): void {
    this.onDestroyed$.next();
    this.onDestroyed$.complete();
  }

  @HostListener('focus')
  @HostListener('keydown')
  public recalcHeightEvent(): void {
    setTimeout(() => {
      const viewportItemsCount =
        this.virtualViewport.getDataLength() || this.getAutocompleteOptionsCount();

      const maxItemsToDisplay = Math.round(BASE_VIEWPORT_HEIGHT / VIEWPORT_ITEM_HEIGHT);

      const viewportCalculatedHeight =
        viewportItemsCount > maxItemsToDisplay
          ? BASE_VIEWPORT_HEIGHT
          : viewportItemsCount * VIEWPORT_ITEM_HEIGHT;
      this.virtualViewport.elementRef.nativeElement.style.height = `${viewportCalculatedHeight}px`;
      this.virtualViewport.checkViewportSize();
    });
  }

  private getAutocompleteOptionsCount(): number {
    if (this.autoTrigger) {
      return this.autoTrigger.autocomplete.options.length;
    }

    return 0;
  }
}

import { ArrowKeyNavigatorDirective } from '@app/shared/directives/arrow-key-navigator.directive';
import { ArrowSearchDisablePipe } from '@app/shared/pipes/arrow-search-disable.pipe';
import { AutocompleteTabDirective } from '@app/shared/directives/mat-autocomplete/mat-autocomplete-tab.directive';
import { ClickableGridHeaderComponent } from '@app/shared/components/ag-grid/clickable-grid-header.component';
import { CommaSeparatorDirective } from '@app/shared/directives/comma-separator.directive';
import { CommonModule } from '@angular/common';
import { CtrlKeyListenerDirective } from '@app/shared/directives/ctrl-key-listener.directive';
import { ShortcutListenerDirective } from '@app/shared/directives/shortcut-listener.directive';
import { DatepickerHelperDirective } from '@app/shared/directives/datepicker-helper.directive';
import { DivResizeDirective } from '@app/shared/directives/div-resize.directive';
import { FilterPipe } from '@app/shared/pipes/filter.pipe';
import { FocusDirective } from '@app/shared/directives/focus.directive';
import { FormControlDisableDirective } from '@app/shared/directives/form-control-disable.directive';
import { FormPermissionPipe } from '@app/shared/pipes/form-permission.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericTableDialogComponent } from '@app/shared/dialogs/generic-table-dialog/generic-table-dialog.component';
import { GridRowCheckboxComponent } from '@app/shared/components/ag-grid/grid-row-checkbox.component';
import { HtmlSafePipe } from '@app/shared/pipes/html-safe.pipe';
import { JobEditPermissionPipe } from '@app/shared/pipes/job-edit-permission.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MobileVisibilityDirective } from '@app/shared/mobile/directives/mobile-visibility.directive';
import { NgModule } from '@angular/core';
import { NoteComponent } from '@app/shared/components/note.component';
import { NumberFormatDirective } from '@app/shared/directives/number-format.directive';
import { ObjectInputFormatterDirective } from '@app/shared/directives/object-input-formatter.directive';
import { OkDialogComponent } from '@app/shared/dialogs/ok-dialog.component';
import { OnlyNumberDirective } from '@app/shared/directives/only-number.directive';
import { PermissionHandlerDirective } from '@app/shared/directives/permissions-handler.directive';
import { PricePercentFormatDirective } from '@app/shared/directives/percent-price-format.directive';
import { PhoneNumberMaskDirective } from '@app/shared/directives/phone-number-mask.directive';
import { PrintOptionsDialogComponent } from '@app/shared/dialogs/print-dialog/print-options-dialog.component';
import { ProgressRingComponent } from '@app/shared/components/progress-ring.component';
import { ProgressSpinnerDirective } from '@app/shared/directives/progress-spinner.directive';
import { RequiredErrorClearDirective } from '@app/shared/directives/required-error-clear.directive';
import { SelectHeaderCheckboxComponent } from '@app/shared/components/ag-grid/select-header-checkbox.component';
import { SimpleInputDialogComponent } from '@app/shared/dialogs/simple-input-dialog/simple-input-dialog.component';
import { StatusModalComponent } from '@app/shared/dialogs/status-modal.component';
import { TimeFormatDirective } from '@app/shared/directives/time-format.directive';
import { TouchAndHoldDirective } from '@app/shared/mobile/directives/touch-and-hold.directive';
import { VirtualViewportCalculatorDirective } from '@app/shared/directives/virtual-viewport-height-calculator.directive';
import { YesNoDialogComponent } from '@app/shared/dialogs/yes-no-dialog.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NoLeadingSpaceDirective } from '@app/shared/directives/no-leading-space.directive';

@NgModule({
  declarations: [
    // Components
    ClickableGridHeaderComponent,
    GenericTableDialogComponent,
    GridRowCheckboxComponent,
    NoteComponent,
    OkDialogComponent,
    PrintOptionsDialogComponent,
    ProgressRingComponent,
    SelectHeaderCheckboxComponent,
    SimpleInputDialogComponent,
    StatusModalComponent,
    YesNoDialogComponent,

    // Directives
    ArrowKeyNavigatorDirective,
    ArrowSearchDisablePipe,
    CommaSeparatorDirective,
    CtrlKeyListenerDirective,
    ShortcutListenerDirective,
    DatepickerHelperDirective,
    DivResizeDirective,
    FocusDirective,
    FormControlDisableDirective,
    MobileVisibilityDirective,
    NoLeadingSpaceDirective,
    NumberFormatDirective,
    ObjectInputFormatterDirective,
    OnlyNumberDirective,
    PermissionHandlerDirective,
    PricePercentFormatDirective,
    PhoneNumberMaskDirective,
    ProgressSpinnerDirective,
    RequiredErrorClearDirective,
    AutocompleteTabDirective,
    TimeFormatDirective,
    TouchAndHoldDirective,
    VirtualViewportCalculatorDirective,

    // Pipes
    ArrowSearchDisablePipe,
    FilterPipe,
    FormPermissionPipe,
    HtmlSafePipe,
    JobEditPermissionPipe
  ],
  exports: [
    // Components
    GridRowCheckboxComponent,
    NoteComponent,
    ProgressRingComponent,
    SelectHeaderCheckboxComponent,

    // Directives
    ArrowKeyNavigatorDirective,
    CommaSeparatorDirective,
    CtrlKeyListenerDirective,
    ShortcutListenerDirective,
    DatepickerHelperDirective,
    DivResizeDirective,
    FocusDirective,
    FormControlDisableDirective,
    PermissionHandlerDirective,
    PricePercentFormatDirective,
    PhoneNumberMaskDirective,
    ProgressSpinnerDirective,
    MobileVisibilityDirective,
    NoLeadingSpaceDirective,
    NumberFormatDirective,
    ObjectInputFormatterDirective,
    OnlyNumberDirective,
    RequiredErrorClearDirective,
    AutocompleteTabDirective,
    TimeFormatDirective,
    TouchAndHoldDirective,
    VirtualViewportCalculatorDirective,

    // Pipes
    ArrowSearchDisablePipe,
    FilterPipe,
    FormPermissionPipe,
    HtmlSafePipe,
    JobEditPermissionPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatListModule,
    MatRadioModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    ReactiveFormsModule,
    MatToolbarModule
  ],
  providers: []
})
export class SharedModule {}

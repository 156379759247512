import { Injectable, Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe should be used to return a boolean value to
 * disable/enable arrow buttons for searching through records.
 * This reduces the number of change detection functions ran on parent
 * component.
 *
 * @param value: the array of searchable values
 * @param searchValue: current value of arrow search, typically the selected item's id in the parent component
 * @param searchFieldName: property of search array object to compare to
 * @param isNext: is next arrow or previous arrow
 *
 */
@Pipe({
  name: 'arrowSearchDisable'
})
@Injectable({
  providedIn: 'root'
})
export class ArrowSearchDisablePipe implements PipeTransform {
  constructor() {}

  public transform(
    value: any[],
    searchValue: any,
    searchFieldName: string,
    isNext: boolean
  ): boolean {
    if (!value?.length) {
      return true;
    }

    const foundIndex = value.findIndex((x) => x[searchFieldName] === searchValue);

    if (foundIndex === 0 && !isNext) {
      return true;
    }

    if (foundIndex === value.length - 1 && isNext) {
      return true;
    }

    return false;
  }
}

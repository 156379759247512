import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { LocalStorageRefService } from '@app/shared/services/browser/local-storage-ref.service';
import { Logger } from '@app/logger/logger';

const LOCAL_STORAGE_THEME_KEY = 'theme';
const DARK_SCHEME_CLASS = 'color-scheme-dark';
const LIGHT_SCHEME_CLASS = 'color-scheme-light';

type ColorSchemeValue = typeof DARK_SCHEME_CLASS | typeof LIGHT_SCHEME_CLASS;

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private _colorScheme: ColorSchemeValue;
  private _renderer: Renderer2;

  public get currentColorScheme(): 'Dark' | 'Light' {
    return this._colorScheme === 'color-scheme-dark' ? 'Dark' : 'Light';
  }

  constructor(
    private localStorageRef: LocalStorageRefService,
    private log: Logger,
    private rendererFactory: RendererFactory2
  ) {
    this._renderer = this.rendererFactory.createRenderer(null, null);
  }

  public initialize(): void {
    this.getColorScheme();
    this.log.info('Setting theme to', this._colorScheme);
    this._renderer.addClass(document.body, this._colorScheme);
  }

  public convertRgbaToHex(color: any): any {
    color = color.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);

    const hex =
      color && color.length === 4
        ? '#' +
          ('0' + parseInt(color[1], 10).toString(16)).slice(-2) +
          ('0' + parseInt(color[2], 10).toString(16)).slice(-2) +
          ('0' + parseInt(color[3], 10).toString(16)).slice(-2)
        : '';

    return hex.toUpperCase();
  }

  public toggleThemeColor(): void {
    this._renderer.removeClass(document.body, this._colorScheme);
    const localStorageColorScheme = this.localStorageRef.getGenericStorageValue<string>(
      LOCAL_STORAGE_THEME_KEY
    );

    if (localStorageColorScheme === DARK_SCHEME_CLASS) {
      this._colorScheme = LIGHT_SCHEME_CLASS;
    } else {
      this._colorScheme = DARK_SCHEME_CLASS;
    }

    this.localStorageRef.setGenericStorageValue(LOCAL_STORAGE_THEME_KEY, this._colorScheme);
    this._renderer.addClass(document.body, this._colorScheme);
  }

  private getColorScheme(): void {
    const localStorageColorScheme = this.localStorageRef.getGenericStorageValue<ColorSchemeValue>(
      LOCAL_STORAGE_THEME_KEY
    );

    if (localStorageColorScheme) {
      this._colorScheme = localStorageColorScheme;
    } else {
      this.detectPrefersColorScheme();
    }
  }

  private detectPrefersColorScheme(): void {
    if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
      this._colorScheme = window.matchMedia('(prefers-color-scheme: dark)').matches
        ? DARK_SCHEME_CLASS
        : LIGHT_SCHEME_CLASS;
    } else {
      this._colorScheme = LIGHT_SCHEME_CLASS;
    }

    this.localStorageRef.setGenericStorageValue(LOCAL_STORAGE_THEME_KEY, this._colorScheme);
  }
}

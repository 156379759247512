export class TicketPrintRequest {
  ticketId: number;
  reprintFlag: boolean;
  requestId: string;
  ticketPrintQueue: string;

  constructor(ticketId: number, reprintFlag: boolean, requestId: string, selectedPrinter: string) {
    this.ticketId = ticketId;
    this.reprintFlag = reprintFlag;
    this.requestId = requestId;
    this.ticketPrintQueue = selectedPrinter;
  }
}

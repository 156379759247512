import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * This directive is used to control the disabled status of formcontrol
 * inputs through template binding.
 *
 * Acts as a stand in for using [disabled]=value on reactive form controls
 * that would normally throw warnings when used.
 */
@Directive({
  selector: '[acsDisableControl]'
})
export class FormControlDisableDirective {
  @Input() set acsDisableControl(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    if (this.ngControl?.control) {
      this.ngControl.control[action]({ emitEvent: this.shouldEmitEvent });
    }
  }

  @Input() shouldEmitEvent = false;

  constructor(private ngControl: NgControl) {}
}

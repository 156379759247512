import { AUSnackBarData } from '@app/shared/models/snackbars/au-snackbar-data.model';
import { AUSnackBarService } from '@app/notifications/au-snackbar.service';
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { hasValue } from '@app/shared/utilities/comparison-helpers.utility';

@Component({
  selector: 'acs-au-error-snack-bar',
  templateUrl: './au-snackbar.component.html'
})
export class AUSnackBarComponent implements OnInit, OnDestroy {
  public isDetailInfoArray = false;
  public isMoreDetailInfoArray = false;
  public showServerErrors = false;

  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: AUSnackBarData,
    private auSnackBarService: AUSnackBarService,
    private snackBarRef: MatSnackBarRef<AUSnackBarComponent>
  ) {
    if (Array.isArray(this.data.detailItems)) {
      this.isDetailInfoArray = true;
    }
    if (Array.isArray(this.data.moreDetailItems)) {
      this.isMoreDetailInfoArray = true;
    }
  }

  @HostListener('window:keyup.esc')
  windowKeyDown(): void {
    this.close();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.auSnackBarService.updateSnackbarObservable.subscribe((updateData: AUSnackBarData) => {
        if (hasValue(updateData.title)) {
          this.data.title = updateData.title;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public close(): void {
    this.snackBarRef.dismiss();
  }

  public displayServerDetails(): void {
    this.showServerErrors = !this.showServerErrors;
  }
}

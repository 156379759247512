import { AgRendererComponent } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

export const GRC_PARAM_FIELD = 'paramField';
export const GRC_VISIBLE_DATA_FIELD = 'visibleDataField';
export const GRC_VISIBLE_DATA_FIELD_VALUE = 'visibleDataFieldValue';

/**
 * Reusable component for displaying checkboxes in ag grids.
 * This component requries certain cellRendererParams to be
 * provided in the column def.
 *
 * - paramField: the field in params.node.data to bind to the checkbox
 * - visibleDataField: the field to check to determine visibility of the checkbox
 * - visibleDataFieldValue: what the value of `visibleDataField` should be to be hidden
 *
 * This component additionally provides a hook into the parent component via
 * the `sendCheckboxChangeEventToComponent` function. If events should be handled in
 * the parent component then the parent component should define a function called
 * `gridRowCheckBoxChanged`
 */
@Component({
  selector: 'acs-grid-row-checkbox',
  templateUrl: './grid-row-checkbox.component.html',
  styles: [':host { display: flex; height: 100%; justify-content: center; align-items: center; }']
})
export class GridRowCheckboxComponent implements AgRendererComponent {
  public paramField = GRC_PARAM_FIELD;
  public visibleDataField = GRC_VISIBLE_DATA_FIELD;
  public visibleDataFieldValue = GRC_VISIBLE_DATA_FIELD_VALUE;

  public params: ICellRendererParams;

  constructor() {}

  public agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  public refresh(): boolean {
    return false;
  }

  public sendCheckboxChangeEventToComponent(): void {
    const parentComponent = this.params.context.componentParent;
    if (typeof parentComponent.gridRowCheckBoxChanged === 'function') {
      parentComponent.gridRowCheckBoxChanged(this.params['paramField']);
    }
  }
}

export class TicketTruckDetails {
  orderId: number;
  scheduleId: number;
  orderNumber: number;
  scheduleLoadId: number;
  statusId: number;
  truckId: number;
  specifiedDriverId: number;
  object: string;

  constructor() {}
}

export class SalesTax {
  object = 'salesTax';
  ticketId: number;
  taxLevelId: number;
  taxLocationId: number;
  taxableSalesAmount: number;
  nontaxableSalesAmount: number;
  taxExemptSalesAmount: number;
  taxRate: number;
}

import { Injectable } from '@angular/core';
import { ThemeService } from '@app/shared/services/theme.service';

const getDocument = (): Document => document;
type BasicHTMLType = 'span' | 'div' | 'button';

@Injectable({ providedIn: 'root' })
export class DocumentRefService {
  constructor(private themeService: ThemeService) {}

  get document(): Document {
    return getDocument();
  }

  public setMutationObserver(
    nodeToWatch: Node,
    callBackFn: (mutations: MutationRecord[], mutationObserver: MutationObserver) => any
  ): MutationObserver {
    const observer = new MutationObserver(
      (mutations: MutationRecord[], mutationObserver: MutationObserver) => {
        callBackFn(mutations, mutationObserver);
      }
    );

    observer.observe(nodeToWatch, {
      childList: true,
      subtree: true
    });

    return observer;
  }

  public copyHTMLNode(element: HTMLElement): HTMLElement {
    return (element as Node).cloneNode(true) as HTMLElement;
  }

  public createElement(type: BasicHTMLType): HTMLElement {
    const node = document.createElement(type);
    return node;
  }

  public createTextNode(text: string): Text {
    return this.document.createTextNode(text);
  }

  public applyStyleToElement(
    element: HTMLElement,
    style: keyof CSSStyleDeclaration,
    value: string
  ): void {
    element.style[style.toString()] = value;
  }

  public addEventListener(
    element: HTMLElement,
    type: keyof HTMLElementEventMap,
    callback: Function
  ): void {
    element.addEventListener(type, () => callback());
  }

  public applyDisableStylesToElement(element: HTMLElement): void {
    element.style.pointerEvents = 'none';
    element.style.color = this.getThemeBasedColor('disable');
    element.style.opacity = '0.2';
  }

  public applyReadOnlyStylesToElement(element: HTMLElement): void {
    element.style.pointerEvents = 'none';
    element.style.color = this.getThemeBasedColor('readOnly');
    element.style.opacity = '0.8';
  }

  public removeElement(element: HTMLElement): void {
    element.remove();
  }

  public hideElement(element: HTMLElement): void {
    element.style.display = 'none';
  }

  private getThemeBasedColor(styleType: 'disable' | 'readOnly'): string {
    const currentTheme = this.themeService.currentColorScheme;

    if (styleType === 'disable') {
      return currentTheme === 'Dark' ? 'rgba(255, 255, 255, 0.38)' : '#000000';
    } else if (styleType === 'readOnly') {
      return currentTheme === 'Dark' ? 'rgba(255, 255, 255, 0.38)' : '#000000';
    }

    return '#000000';
  }
}

import { Directive, AfterContentInit, OnDestroy, ElementRef, Input } from '@angular/core';
import { UntypedFormControl, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({ selector: 'input[acsObjectInputFormatter]' })
export class ObjectInputFormatterDirective implements AfterContentInit, OnDestroy {
  @Input() acsObjectInputFormatter: string;
  private el: HTMLInputElement;
  private formControl: UntypedFormControl;
  private formChangeSub = new Subscription();

  constructor(private baseControl: NgControl, private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  ngAfterContentInit(): void {
    this.formControl = this.baseControl.control as UntypedFormControl;

    this.formChangeSub.add(
      this.formControl.valueChanges.subscribe((val) => {
        console.log(val);
        if (val) {
          this.el.value = val[this.acsObjectInputFormatter];
          this.formControl.patchValue(val, { emitModelToViewChange: false, emitEvent: false });
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.formChangeSub.unsubscribe();
  }
}

import { AppConfig } from '@app/app.config';
import { HttpService } from '@app/security/shared/http.service';
import { ISystemParameter } from '@app/system-parameters/shared/parameter.interface';
import { Injectable } from '@angular/core';
import { Logger } from '@app/logger/logger';
import { LoginStatusModel } from '@app/security/shared/login-status-change.model';
import { LoginStatusService } from '@app/security/shared/login-status.service';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { SystemParameter } from '@app/system-parameters/shared/parameter.model';
import { map, refCount, publishReplay } from 'rxjs/operators';

const lgSfx = '\t(system-parameters.service.ts)';

@Injectable({ providedIn: 'root' })
export class SystemParameterService {
  public newSystemParameterFormEvent: Subject<void> = new Subject();
  public formInvalidEvent: Subject<boolean> = new Subject();
  public productsToIgnore: number[] = [];
  public systemParameters: ISystemParameter;

  private loadSystemParametersObserver: Observable<ISystemParameter>;

  private _systemParameterForm: SystemParameter;
  get systemParameterForm(): SystemParameter {
    return this._systemParameterForm;
  }
  set systemParameterForm(val: SystemParameter) {
    this._systemParameterForm = val;
  }

  constructor(
    private config: AppConfig,
    private http: HttpService,
    private log: Logger,
    private loginStatusService: LoginStatusService
  ) {
    this.loginStatusService.loginStatus.subscribe((ls) => {
      this.onLoginStatusChange(ls);
    });
  }

  public accountingIsEnabledForItemClassRetreival(currentParams: SystemParameter): boolean {
    return (
      (currentParams.greatPlains || currentParams.accountingIsSageEnabled) &&
      Boolean(currentParams.accountingDatabase) &&
      currentParams.accountingUseAllExceptInventory
    );
  }

  public getParameters(): Observable<ISystemParameter> {
    if (
      typeof this.loadSystemParametersObserver === 'undefined' ||
      this.loadSystemParametersObserver === null
    ) {
      this.loadParameters();
    }

    return this.loadSystemParametersObserver.pipe(map(() => this.systemParameters));
  }

  public sendParameterUpdate(data: ISystemParameter): Observable<ISystemParameter> {
    this.log.log(`Loading System Parameters for ADMIN from the server ${lgSfx}`);
    const API_ENDPOINT = '/api/v1/parameters/';
    const endpoint = this.config.getServerUri() + API_ENDPOINT;

    return this.http.put<ISystemParameter>(endpoint, data, SystemParameter, undefined, true);
  }

  public getParametersForAdmin(): Observable<ISystemParameter> {
    this.log.log(`Loading System Parameters for ADMIN from the server ${lgSfx}`);
    const API_ENDPOINT = '/api/v1/parameters/?adminRequest=true';
    const endpoint = this.config.getServerUri() + API_ENDPOINT;

    return this.http.get<ISystemParameter>(endpoint, SystemParameter, undefined, true);
  }

  public loadParameters(): Observable<ISystemParameter> {
    this.log.log(`Loading System Parameters from the server ${lgSfx}`);
    const API_ENDPOINT = '/api/v1/parameters/';
    const endpoint = this.config.getServerUri() + API_ENDPOINT;

    if (this.loadSystemParametersObserver) {
      return this.loadSystemParametersObserver;
    }

    this.loadSystemParametersObserver = this.http
      .getList<ISystemParameter>(endpoint, SystemParameter, undefined, true)
      .pipe(
        map((resp) => {
          this.systemParameters =
            resp.length > 0 ? Object.assign(new SystemParameter(), resp[0]) : undefined;

          /*** System Products to ignore in OE and Ticketing ***/
          this.productsToIgnore.push(this.systemParameters.shortLoadProductId);
          /*** ***/
          return this.systemParameters;
        }),
        publishReplay(1),
        refCount()
      );

    return this.loadSystemParametersObserver;
  }

  public getSystemProducts(systemParameter?: SystemParameter): number[] {
    const param = systemParameter ?? this.systemParameters;
    return [
      param.deliveryProductId,
      param.publicWorksProductId,
      param.shortLoadProductId,
      param.standbyProductId,
      param.weekendDeliveryProductId,
      param.blkRestockingProductId,
      param.blkPaletteProductId,
      param.concreteAutoPrimaryProductId,
      param.aggregateAutoPrimaryProductId
    ];
  }

  public getEnabledTruckTrackingSystemTypes(): number[] {
    return [
      this.systemParameters.conEnableTruckTracking ? 1 : null,
      this.systemParameters.aggEnableTruckTracking ? 2 : null,
      this.systemParameters.blkEnableTruckTracking ? 3 : null
    ].filter(Number);
  }

  public resolve(): Observable<ISystemParameter> {
    return this.loadParameters();
  }

  public cleanCache(): void {
    this.systemParameters = undefined;
    this.loadSystemParametersObserver = undefined;
  }

  private onLoginStatusChange(loginStatus: LoginStatusModel): void {
    if (loginStatus.loggedIn === false) {
      this.cleanCache();
    }
  }
}

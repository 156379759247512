import { ISystemParameter } from '@app/system-parameters/shared/parameter.interface';
import { Moment } from 'moment';
import {
  convertDateToIsoString,
  convertIso8601ToDate
} from '@app/shared/utilities/date-helpers.utility';

export class SystemParameter implements ISystemParameter {
  accountingDatabase: string;
  accountingEnforceInventoryQoHForSales: boolean;
  accountingIsSageEnabled: boolean;
  accountingUseAllExceptInventory: boolean;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  address5: string;
  address6: string;
  address7: string;
  aggDaysBetweenTare: number;
  aggEnableToPlantTravelMinutes: boolean;
  aggEnableTruckTracking: boolean;
  aggFuelSurchargePercentage: number;
  aggFuelSurchargeProductId: number;
  aggStandbyMinutesPerQuantity: number;
  aggUseMobileSimulationFlag: boolean;
  aggregateAutoPrimaryProductId: number;
  allowCreateMapPage: boolean;
  allowQuoteAssignment: boolean;
  allowSpecJobOtherTypeProducts: boolean;
  allowVoidInEditTicketsOnly: boolean;
  aujsVersion: string;
  blkEnableTruckTracking: boolean;
  blkPaletteProductId: number;
  blkRestockingProductId: number;
  blkUseMobileSimulationFlag: boolean;
  byPassDivisionInQuote: boolean;
  cancelOrderReasonChange: boolean;
  certifiedMailFee: number;
  clearCustomerPONumberOnCopyOrder: boolean;
  clearJobPhoneOnNewOrder: boolean;
  clearLotBlockNumberOnCopyOrder: boolean;
  clearTaxOnCopyOrder: boolean;
  coePrintMaterialTypeDefault: number;
  companyName: string;
  conEnableToPlantTravelMinutes: boolean;
  conEnableTruckTracking: boolean;
  conStandbyMinutesPerQuantity: number;
  conUseMobileSimulationFlag: boolean;
  concreteAutoPrimaryProductId: number;
  daysToAmend: number;
  daysToLien: number;
  daysToPrelim: number;
  defaultMaxLoadSize: number;
  defaultMaxLoadSizeForNewMixes: boolean;
  defaultPerYardEachProducts: boolean;
  defaultQuoteSpecJob: boolean;
  deliveryProductId: number;
  disallowOeOrderTypeChange: boolean;
  endingCertifiedMailNumber: number;
  forceUserToEnterOeShipDate: boolean;
  greatPlains: boolean;
  id: number;
  leadTime: number;
  licenseKey: string;
  lockCancelledOrders: boolean;
  lockCopyOrder: boolean;
  lockInvoicedOrders: boolean; // setting is currently ignored in aujs2
  lockOrderDate: boolean;
  lockOrderDelete: boolean;
  lockOrderNumber: boolean;
  lockSlump: boolean;
  lockTravelTime: boolean;
  meanTravelTimeDays: number;
  messageQueueType: number;
  nextCertifiedMailNumber: number;
  object: string;
  onJobWaitTime: number;
  oeReasonChange: boolean;
  overTimeDaily: number;
  overTimeWeekly: number;
  phoneNumberInputMask: string;
  postageFee: number;
  prelimPercentage: number;
  printPrelimCustomerCopy: boolean;
  preventCopyOrderToDifferentMonth: boolean;
  pstTaxExemptLevelId: number;
  publicWorksOrderConfirmationMessage: string;
  publicWorksProductId: number;
  quotedChargeOption: number;
  reportFobOrderType: boolean;
  requireJobAddresses2357: boolean;
  requireCopyOrderDate: boolean;
  requireJobPhone: boolean;
  requireMaterialReceiptNumber: boolean;
  requireOeSlump: boolean;
  requireOrderedBy: boolean;
  requireReviewedForBilling: boolean;
  requireUsageType: boolean;
  requireVerifiedOrderForTicketing: boolean;
  returnReceiptFee: number;
  sackPricingFlag: boolean;
  saveOosTruckInfo: boolean;
  selectMaterialReceiptTruckFromList: boolean;
  shortLoadProductId: number;
  sourceTaxingFlag: number;
  standbyProductId: number;
  startingCertifiedMailNumber: number;
  updateOrderReceiver: boolean;
  useAccountingInventory: boolean;
  useAggSystemTickets: boolean;
  useBatchIdentifier: boolean;
  useBlockSystemTickets: boolean;
  useCartageSystem: boolean;
  useCashTransaction: number;
  useCityLookup: boolean;
  useClosestPlant: boolean;
  useClosestPlantInQuote: boolean;
  useConSystemTickets: boolean;
  useDirections: boolean;
  useInsightBidding: boolean;
  uninvoicedSalesIncludesUnshippedLoads: boolean;
  useMaping: boolean;
  useMaxOrderNumberFlag: boolean;
  useMaxTaxableSales: boolean;
  useMultiplePrelimForms: boolean;
  usePlantPricing: boolean;
  useQuotePlant: boolean;
  useQuotePriceCategory: boolean;
  useSingleAggregateTrailer: boolean;
  useSourceLocations: boolean;
  useStreetLookup: boolean;
  useTaxLookup: boolean;
  useTaxSchedulesFlag: boolean;
  useTruckMaxLoadQuantity: boolean;
  validateDriver: boolean;
  validateQuoteTargetQuantity: boolean;
  weekendDeliveryProductId: number;

  private _aggDriverCallInCutoffTime: Moment;
  get aggDriverCallInCutoffTime(): Moment {
    return this._aggDriverCallInCutoffTime;
  }
  set aggDriverCallInCutoffTime(val) {
    this._aggDriverCallInCutoffTime = convertIso8601ToDate(val);
  }

  private _blkDriverCallInCutoffTime: Moment;
  get blkDriverCallInCutoffTime(): Moment {
    return this._blkDriverCallInCutoffTime;
  }
  set blkDriverCallInCutoffTime(val) {
    this._blkDriverCallInCutoffTime = convertIso8601ToDate(val);
  }

  private _conDriverCallInCutoffTime: Moment;
  get conDriverCallInCutoffTime(): Moment {
    return this._conDriverCallInCutoffTime;
  }
  set conDriverCallInCutoffTime(val) {
    this._conDriverCallInCutoffTime = convertIso8601ToDate(val);
  }

  private _onJobWaitTimeAsDate: Moment;
  get onJobWaitTimeAsDate(): Moment {
    return this._onJobWaitTimeAsDate;
  }
  set onJobWaitTimeAsDate(val) {
    this._onJobWaitTimeAsDate = convertIso8601ToDate(val);
  }

  toJSON(): Object {
    return convertDateToIsoString(this);
  }
}

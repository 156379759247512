export class AUSnackBarData {
  closeSymbol = 'fa-times-circle';
  expandSymbol = 'fa-angle-down';
  closeExpandSymbol = 'fa-angle-up';
  detailItems: string | string[];
  moreDetailItems: string | string[];
  isCloseSymbolIcon = true;
  isMoreSymbolIcon = true;
  isListOrdered = false;
  title: string;
}

import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  formatInputForDollarOrPercent,
  formatDollarOrPercentValueForModel
} from '@app/shared/utilities/price-input.utility';
import { hasValue } from '@app/shared/utilities/comparison-helpers.utility';

/**
 * This directive is used to style input contents into a standard
 * percent or dollar format.
 *
 * Typically the formatting is dependent on the value of another
 * form control. That form control's name should be passed to
 * the directive. If there isn't one a value for `siblingValueOverride`
 * should be provided where false would indicate dollar formatting
 * and true indicates percent formatting.
 *
 * Note: Currently if using `siblingValueOverride` there is no way to
 * initially format an inputs value when it is first placed in a form.
 * Update: We've added logic to handle initial load/value placement
 * but it requires forms being properly marked dirty/clean to
 * distinguish between programmatic and user input.
 *
 * Note: It's important to use a debounce time on the valueChanges for
 * the injected FormControl/FormGroup. Otherwise a race condition may occur
 * where the emitted form value from the directive will have the '$' character
 * included. Another way around this is to use the function
 * formatDollarOrPercentValueForModel in the component.
 */
@Directive({ selector: '[acsPricePercentFormat]' })
export class PricePercentFormatDirective implements OnInit, OnDestroy {
  @Input() siblingControlName: string;
  @Input() siblingValueOverride: boolean;
  @Input() initFormat: boolean;

  private el: HTMLInputElement;
  private isPercent: boolean;
  private mainControl: UntypedFormControl;
  private siblingControl: UntypedFormControl;
  private subscriptions: Subscription = new Subscription();

  constructor(private baseControl: NgControl, private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('blur', ['$event.target.value'])
  blurEvent(value: string): void {
    this.formatValue(value);
  }

  @HostListener('focus', ['$event.target.value'])
  focusEvent(): void {
    this.el.select();
  }

  @HostListener('keyup', ['$event.target.value'])
  keyupEvent(value): void {
    this.formatValueForModel(value);
  }

  ngOnInit(): void {
    this.mainControl = this.baseControl.control as UntypedFormControl;
    if (this.mainControl) {
      if (!hasValue(this.siblingValueOverride)) {
        this.siblingControl = this.mainControl.parent.get(
          this.siblingControlName
        ) as UntypedFormControl;
        this.subscriptions.add(
          this.siblingControl.valueChanges.subscribe((val) => {
            this.isPercent = val;
            if (this.siblingControl.dirty) {
              this.mainControl.reset();
            } else {
              this.formatValue(this.mainControl.value);
            }
          })
        );
      } else {
        this.isPercent = this.siblingValueOverride;
        if (this.initFormat) {
          this.formatValue(this.mainControl.value);
        }
        this.subscriptions.add(
          this.mainControl.valueChanges.subscribe((val) => {
            if (!this.mainControl.dirty) {
              this.formatValue(val);
            }
          })
        );
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public forceFormat(): void {
    const currentValue = this.mainControl.value;
    this.formatValue(currentValue);
  }

  private formatValue(value): void {
    if (hasValue(value)) {
      // formats to dollar or percent for display
      this.el.value = formatInputForDollarOrPercent(value, this.isPercent);

      // removes dollar  sign or percent sign for proper input data.
      this.formatValueForModel(this.el.value);
    }
  }

  private formatValueForModel(value: string): void {
    this.mainControl.setValue(formatDollarOrPercentValueForModel(value), {
      emitModelToViewChange: false,
      emitEvent: false
    });
  }
}

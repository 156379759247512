<div mat-dialog-title class="d-flex row">
  <h2>Print</h2>
  <button class="ml-auto" mat-button (click)="onNoClick()">
    <mat-icon color="accent" fontIcon="fa-times"></mat-icon>
  </button>
</div>

<form [formGroup]="printOptionsForm" mat-dialog-content novalidate class="p-4">
  <div class="row">
    <mat-form-field class="col">
      <mat-label>Printer Name</mat-label>
      <mat-select
        formControlName="printerName">
        <mat-option *ngFor="let destination of printDestinations; trackBy: getIdentifierForIterableItem" [value]="destination.prefixedName">
          {{ destination.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row" *ngIf="data.requiresOrientation">
    <mat-radio-group class="p-1 col d-flex flex-column border" formControlName="orientation">
      <mat-label>Orientation</mat-label>
      <mat-radio-button color="primary" [value]="1" class="padding">Portrait</mat-radio-button>
      <mat-radio-button color="primary" [value]="2" class="padding">Landscape</mat-radio-button>
    </mat-radio-group>

    <!-- number of copies currently not supported
    <div class="col ml-xl-3 p-1 border d-flex flex-column">
      <mat-label>Copies</mat-label>
      <mat-form-field class="mt-2">
        <input
          min="1"
          type="number"
          acsOnlyNumber
          formControlName="copies"
          matInput
          placeholder="Number of copies">
        </mat-form-field>
    </div> -->
  </div>
</form>

<div mat-dialog-actions class="pr-2">
  <button
    [disabled]="isPrintDisabled()"
    (click)="print()"
    class="md-primary  ml-auto"
    color="primary"
    mat-raised-button>
    Print
  </button>

  <button
    (click)="onNoClick()"
    mat-raised-button>
    Cancel
  </button>
</div>

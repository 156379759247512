import { Ticket } from '@app/ticketing/models/ticket.model';
import { TicketProduct } from '@app/products/models/ticket-product.model';
import { TicketUpdatePrintRequest } from '@app/ticketing/models/printing/ticket-update-print-request.model';
import { convertDateToIsoString } from '@app/shared/utilities/date-helpers.utility';

export class TicketUpdate {
  dispatchNotes: string;
  deliveryChargeTypeIdOverride?: number;
  object: string;
  preventShortLoadCharge: boolean;
  scheduleId: number;
  scheduleLoadId: number;
  systemTypeId: number;
  ticketNotes: string;
  truckTypeId: number;
  ticket: Ticket;
  printRequest: TicketUpdatePrintRequest;
  ticketProducts: Array<TicketProduct>;

  constructor() {
    this.object = 'ticketUpdate';
    this.ticket = new Ticket();
  }

  toJSON(): Object {
    return convertDateToIsoString(this);
  }
}

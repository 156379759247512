import { AuthorizationService } from '@app/security/shared/authorization.service';
import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { FormService } from '@app/forms/shared/forms.service';
import { KEY_CODE } from '@app/shared/constants/key-code.const';

@Directive({
  selector: '[acsCtrlKeyListener]'
})
export class CtrlKeyListenerDirective {
  @Output() saveSelected: EventEmitter<boolean> = new EventEmitter();
  @Output() ctrlClickSelected: EventEmitter<boolean> = new EventEmitter();

  constructor(private authService: AuthorizationService, private formService: FormService) {}

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.ctrlKey && event.key === KEY_CODE.S) {
      const formId = this.getFormId();
      if (this.authService.getFormPermissions(formId).full) {
        this.stopDefaultActions(event);
        this.saveSelected.emit(true);

        setTimeout(() => {
          this.saveSelected.emit(false);
        }, 200);
      }
    }
  }

  private stopDefaultActions(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  private getFormId(): number {
    if (this.formService.formOverrideId) {
      return this.formService.formOverrideId;
    }

    return this.formService.getCurrentForm()?.id;
  }
}
